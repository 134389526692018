import React, { useContext, useEffect, useState } from 'react'
import Header from '../../Components/Header'
import { useNavigate, useParams } from 'react-router-dom'
import toast from 'react-simple-toasts';
import axios from 'axios';
import BaseUrl from '../../BaseUrl';
import moment from 'moment';

const EditTasks = () => {

    const navigate = useNavigate();
    const { id } = useParams();

    const [branchData, setBranchData] = useState([]);
    const [assignData, setAssignData] = useState([]);

    const [name, setName] = useState(null);
    const [lastDate, setLastDate] = useState(null);
    const [branchId, setBranchId] = useState(null);
    const [assignId, setAssignId] = useState(null);

    const [data, setData] = useState({});

    useEffect(() => {
        try {
            axios.get(`${BaseUrl.baseUrl}/branch/get`).then(res => {
                if (res.data.status === 'ok') {
                    setBranchData(res.data.data);
                } else if (res.data.status === 'fail') {
                    toast('🚩' + res.data.message);
                }
            }).catch(err => {
                toast('🚨' + err.message);
            });
        } catch (err) {
            toast('🚨 Something went wrong!');
        }
    }, []);

    useEffect(() => {
        try {
            axios.get(`${BaseUrl.baseUrl}/employe/get`).then(res => {
                if (res.data.status === 'ok') {
                    setAssignData(res.data.data);
                } else if (res.data.status === 'fail') {
                    toast('🚩' + res.data.message);
                }
            }).catch(err => {
                toast('🚨' + err.message);
            });
        } catch (err) {
            toast('🚨 Something went wrong!');
        }
    }, []);

    useEffect(() => {
        try {
            axios.get(`${BaseUrl.baseUrl}/task/get/${id}`).then(res => {
                if (res.data.status === 'ok') {
                    setData(res.data.data);
                    setName(res.data.data.name);
                    setLastDate(res.data.data.lastDate);
                    setBranchId(res.data.data.branchId); // Set previous value of companyId
                    setAssignId(res.data.data.assignTo); // Set previous value of companyId
                    
                } else if (res.data.status === 'fail') {
                    toast('🚩' + res.data.message);
                }
            }).catch(err => {
                toast('🚨' + err.message);
            });
        } catch (err) {
            toast('🚨 Something went wrong!');
        }
    }, []);

    const submit = () => {
        try {
            const param = new FormData();
            if (name !== data?.name) {
                param.append('name', name);
            }
            if (lastDate !== data?.lastDate) {
                param.append('lastDate', lastDate);
            }
            if (branchId !== data?.branchId) {
                param.append('branchId', branchId);
            }
            if (assignId !== data?.assignTo) {
                param.append('assignTo', assignId);
            }
            axios.put(`${BaseUrl.baseUrl}/task/update/${id}`, param).then(res => {
                if (res.data.status === 'ok') {
                    toast('🎉 Updated Successfully!');
                    navigate('/tasks');
                } else if (res.data.status === 'fail') {
                    toast('🚩' + res.data.message);
                }
            }).catch(err => {
                toast('🚨' + err.message);
            });
        } catch (err) {
            toast('🚨 Something went wrong!');
        }
    };

    return (
        <div>
            <div>
                <Header name={'Edit Tasks'} />
            </div>
            <div className='bg-light rounded-2 p-3  mb-4 me-3'>
                <div className='d-flex justify-content-between align-items-start mb-3'>
                    <h3>Edit Tasks</h3>
                </div>
                <div className='row  w-100'>
                    <div className='col-md-6 mb-4'>
                        <div className='border border-dark rounded-2 bg-white d-flex align-items-center px-3'>
                            <i className='bi bi-pencil'></i>
                            <input type='text' className='border-0 rounded-2 p-2 w-100 px-3' placeholder='Enter Task Name' defaultValue={name} onChange={(e) => { setName(e.target.value) }} />
                        </div>
                    </div>
                    <div className='col-md-6 mb-4'>
                        <div className='border border-dark rounded-2 bg-white d-flex align-items-center px-3'>
                            <i className='bi bi-pencil'></i>
                            <input type='date' className='border-0 rounded-2 p-2 w-100 px-3' placeholder='Select last date' value={moment(lastDate).format('YYYY-MM-DD')} onChange={(e) => { setLastDate(e.target.value) }} />
                        </div>
                    </div>
                    <div className='col-md-6 mb-4'>
                        <div className='border border-dark rounded-2 bg-white d-flex align-items-center px-3'>
                            <select className="form-select" aria-label="Default select example" value={branchId} onChange={(e) => { setBranchId(e.target.value) }}>
                                <option value="" disabled>Select Company</option>
                                {branchData?.length > 0 ? branchData.map(i => (
                                    <option key={i?._id} value={i?._id}>{i?.name}</option>
                                )) : <option disabled>No data found!</option>}
                            </select>
                        </div>
                    </div>
                    <div className='col-md-6 mb-4'>
                        <div className='border border-dark rounded-2 bg-white d-flex align-items-center px-3'>
                            <select className="form-select" aria-label="Default select example" onChange={(e) => { setAssignId(e.target.value) }}>
                                <option value="" disabled>Select Assign To</option>
                                {assignData?.length > 0 ? assignData.map(i => (
                                    <option key={i?._id} value={i?._id}>{i?.firstName}</option>
                                )) : <option disabled>No data found!</option>}
                            </select>
                        </div>
                    </div>
                </div>
                <div className='p-1 px-3 bg-warning rounded-2 d-flex justify-content-center align-items-center set-shadow' style={{ cursor: 'pointer', width: '140px' }} onClick={() => { submit() }}>
                    <h5 className='m-0 p-0'>Submit</h5>
                </div>
            </div>
        </div>
    )
}

export default EditTasks;
