import React, { useEffect, useState } from "react";
import Header from "../../Components/Header";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import BaseUrl from "../../BaseUrl";
import toast from "react-simple-toasts";
import Swal from "sweetalert2";
import moment from "moment";

const SubQuestion = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);


  const { questionId, cId } = useParams();

  useEffect(() => {
    try {
      axios
        .get(`${BaseUrl.baseUrl}/subQuestionAll/question/${questionId}`)
        .then((res) => {
          if (res.data.status === "ok") {
            setData(res.data.data);
          } else if (res.data.status === "fail") {
            toast("🚩" + res.data.message);
          }
        })
        .catch((err) => {
          toast("🚨" + err.message);
        });
    } catch (err) {
      toast("🚨 Something went wrong!");
    }
  }, []);


  useEffect(() => {
    try {

      axios.get(`${BaseUrl.baseUrl}/task/createQuest`).then(res => {
        if (res.data.status === 'ok') {
          // setStore(res.data.data)
        }

      }).catch(err => {
        // toast('🚨' + err.message)
        console.log(err);
      })


    } catch (err) {
      console.log(err);
      // toast('🚨 Something went wrong!')
    }

  }, [])



  const del = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          axios
            .delete(`${BaseUrl.baseUrl}/subQuestionAll/delete/${id}`)
            .then((res) => {
              if (res.data.status === "ok") {
                toast("🎉 Deleted Successfully!");

                axios
                  .get(`${BaseUrl.baseUrl}/subQuestionAll/question/${questionId}`)
                  .then((res) => {
                    if (res.data.status === "ok") {
                      setData(res.data.data);
                    } else if (res.data.status === "fail") {
                      toast("🚩" + res.data.message);
                    }
                  })
                  .catch((err) => {
                    toast("🚨" + err.message);
                  });
              } else if (res.data.status === "fail") {
                toast("🚩" + res.data.message);
              }
            })
            .catch((err) => {
              toast("🚨" + err.message);
            });
        } catch (err) {
          toast("🚨 Something went wrong!");
        }
      }
    });
  };

  return (
    <div>
      <div>
        <Header name={"Question"} />
      </div>

      <div className="bg-light rounded-2 p-3  mb-4 me-3">
        <div className="d-flex justify-content-between align-items-start mb-3">
          <h3>Question</h3>
          <div className="d-flex gap-3">
            <div
              className="p-1 px-3 bg-warning rounded-2 d-flex justify-content-center align-items-center set-shadow"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate(`/addsubQuestion/${cId}/${questionId}`);
              }}
            >
              <h5 className="m-0 p-0">Add</h5>
            </div>

            <div className='p-1 px-3 bg-warning rounded-2 d-flex justify-content-center align-items-center set-shadow' style={{ cursor: 'pointer' }} onClick={() => { navigate(`/question/${cId}`) }}>
              <h5 className='m-0 p-0'>Back</h5>
            </div>

          </div>
        </div>

        <div className="border border-dark rounded-2 p-3 w-100">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Image</th>
                  <th scope="col">Question</th>
                  <th scope="col">Total Score</th>
                </tr>
              </thead>
              <tbody>


                {data?.length > 0 ? (
                  data?.map((i, index) => {
                    return (
                      <>
                        <tr>
                          <td>{index+1}</td>
                          <td>
                            <img src={i?.productImage} style={{ width: '45px', height: '45px', objectFit: 'cover', borderRadius: 200 }} />
                          </td>
                          <td>{i?.productName}</td>
                          <td>{i?.totalScore}</td>
                          <td>
                            <div className="d-flex align-items-center gap-3">
                              <button
                                title="Edit"
                                className="btn btn-outline-info btn-sm"
                                onClick={() => {
                                  navigate(`/editsubQuestion/${i?._id}/${questionId}`);
                                }}
                              >
                                <i className="bi bi-pencil fs-6"></i>
                              </button>
                              <button
                                title="Delete"
                                className="btn btn-outline-danger btn-sm"
                                onClick={() => {
                                  del(i?._id);
                                }}
                              >
                                <i className="bi bi-trash-fill"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      </>
                    );
                  })
                ) : (
                  <p>No data found!</p>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubQuestion;
