import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import toast from "react-simple-toasts";

const Sidebar = () => {
  const location = useLocation();
  const isActive = (route) => location.pathname.includes(route);



  const navigate = useNavigate();



  useEffect(()=>{
    if(!localStorage.getItem('login')){
      toast('Please login!')
      navigate('/')
    }
  },[])

  return (
    <div
      className="bg-light py-3 pe-3"
      style={{ height: "100vh", width: "240px", overflowY: "scroll" }}
    >
      <div className="p-3 mb-2 d-flex justify-content-center align-items-center">
        <img
          src={require("../Assests/logo.png")}
          alt="logo"
          style={{ width: "110px", height: "80px", objectFit: "contain" }}
        />
      </div>

      <div
        className={`${
          isActive("/home")
            ? "bg-warning border border-start-0 border-dark set-border set-shadow"
            : ""
        } p-3 d-flex my-2  align-items-center rounded-2 gap-2 `}
        style={{ cursor: "pointer" }}
        onClick={() => {
          navigate("/home");
        }}
      >
        <i className="bi bi-ui-checks-grid"></i>
        <h6>Dashboard</h6>
      </div>

      <div
        className={`${
          isActive("/team")
            ? "bg-warning border border-start-0 border-dark set-border set-shadow"
            : ""
        } p-3 d-flex my-3  align-items-center rounded-2 gap-2 `}
        style={{ cursor: "pointer" }}
        onClick={() => {
          navigate("/team");
        }}
      >
        <i className="bi bi-people-fill"></i>
        <h6>Team</h6>
      </div>


      <div
        className={`${
          isActive("/project")
            ? "bg-warning border border-start-0 border-dark set-border set-shadow"
            : ""
        } p-3 d-flex my-3  align-items-center rounded-2 gap-2 `}
        style={{ cursor: "pointer" }}
        onClick={() => {
          navigate("/project");
        }}
      >
        <i className="bi bi-building-fill"></i>
        <h6>Projects</h6>
      </div>




      <div
        className={`${
          isActive("/reports")
            ? "bg-warning border border-start-0 border-dark set-border set-shadow"
            : ""
        } p-3 d-flex my-3 align-items-center rounded-2 gap-2 `}
        style={{ cursor: "pointer" }}
        onClick={() => {
          navigate("/reports");
        }}
      >
        <i className="bi bi-clipboard-data-fill"></i>
        <h6>Task Reports</h6>
      </div>
      <div
        className={`${
          isActive("/performances")
            ? "bg-warning border border-start-0 border-dark set-border set-shadow"
            : ""
        } p-3 d-flex my-3  align-items-center rounded-2 gap-2 `}
        style={{ cursor: "pointer" }}
        onClick={() => {
          navigate("/performances");
        }}
      >
        <i className="bi bi-graph-up"></i>
        <h6>Performances</h6>
      </div>


      {/* <div
        className={`${
          isActive("/city")
            ? "bg-warning border border-start-0 border-dark set-border set-shadow"
            : ""
        } p-3 d-flex my-3  align-items-center rounded-2 gap-2 `}
        style={{ cursor: "pointer" }}
        onClick={() => {
          navigate("/city");
        }}
      >
        <i className="bi bi-houses-fill"></i>
        <h6>City</h6>
      </div> */}


      <div className="mt-5">

      <div className="d-none d-sm-flex  px-4  py-2 bg-warning rounded-2 d-flex justify-content-center align-items-center" onClick={()=>{
        navigate('/')
        localStorage.clear()
        }} style={{cursor:'pointer'}}>
        <h6 className="m-0 p-0 ">Logout</h6>
      </div>

      </div>
      
    </div>
  );
};

export default Sidebar;
