import React, { useState } from 'react'
import './Login.css'
import { useNavigate } from 'react-router-dom'
import CarouselSlider from '../Components/CarouselSlider'
import toast from 'react-simple-toasts'
import axios from 'axios'
import BaseUrl from '../BaseUrl'

const Login = () => {

    const navigate = useNavigate()
    const [activeTab, setActiveTab] = useState('Admin')
    const [email, setEmail] = useState(null)
    const [password, setPassword] = useState(null)


    const submit = () => {
        try {

            if (!email) {
                toast('🚨 Must enter email!')
            }
            else if (!password) {
                toast('🚨 Must enter password!')
            }
            else {

                if (email === 'admin@gmail.com') {
                    if (password === 'admin') {
                        localStorage.setItem('login', "Admin")
                        toast('🎉 Login Successfully!')
                        navigate('/home')
                    } else {
                        toast('🚨' + 'Invalid password')
                    }

                } else {
                    toast('🚨' + 'Invalid email')
                }

            }
        } catch (err) {
            toast('🚨 Something went wrong!')
        }
    }



    return (
        <div className='mt-5'>
            <div id="form_wrapper">
                <div id="form_left">
                    <CarouselSlider />
                </div>
                <div className='d-flex flex-column gap-5 '>
                    <img src={require('../Assests/logo1.png')} alt='logo' style={{ width: '170px', height: '70px', objectFit: 'contain' }} />

                    <div className='d-flex gap-3 align-items-center'>
                        <div className={`p-1 px-3 bg-${activeTab === 'Admin' ? 'warning' : 'light'} rounded-2 d-flex justify-content-center align-items-center set-shadow`} style={{ cursor: 'pointer', width: '140px' }} onClick={() => {
                            setActiveTab('Admin')
                            
                        }}>
                            <h5 className='m-0 p-0'>Admin</h5>
                        </div>

                        {/* <div className={`p-1 px-3 bg-${activeTab === 'Company' ? 'warning' : 'light'} rounded-2 d-flex justify-content-center align-items-center set-shadow`} style={{ cursor: 'pointer', width: '140px' }} onClick={() => {
                            setActiveTab('Company')
                            localStorage.setItem('login', "Company")
                        }}>
                            <h5 className='m-0 p-0'>Company</h5>
                        </div> */}


                    </div>

                    <div className='border border-dark rounded-2 bg-white d-flex align-items-center px-3' style={{ height: '50px' }}>
                        <i className='bi bi-person'></i>
                        <input type='email' className='border-0 rounded-2 p-2 w-100 px-3' placeholder='Enter Your Email' onChange={(e) => { setEmail(e.target.value) }} />
                    </div>
                    <div className='border border-dark rounded-2 bg-white d-flex align-items-center px-3' style={{ height: '50px' }}>
                        <i className='bi bi-lock'></i>
                        <input type='password' className='border-0 rounded-2 p-2 w-100 px-3' placeholder='Enter Your Password' onChange={(e) => { setPassword(e.target.value) }} />
                    </div>

                    <div className='p-1 px-3 bg-warning rounded-2 d-flex justify-content-center align-items-center set-shadow' style={{ cursor: 'pointer', height: '50px' }} onClick={() => { submit() }}>
                        <h5 className='m-0 p-0'>Submit</h5>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Login