import React, { useContext, useEffect, useState } from 'react'
import Header from '../../Components/Header'
import {  useNavigate, useParams } from 'react-router-dom'
import MapContext from '../../Components/MapContext';
import toast from 'react-simple-toasts';
import axios from 'axios';
import BaseUrl from '../../BaseUrl';
import Swal from 'sweetalert2';

const EditCity= () => {

    const navigate = useNavigate()
    const {id}=useParams()

    




    const [name, setName] = useState(null)
    const [countryName, setCountryName] = useState(null)

    

    const [data, setData] = useState({})


    useEffect(() => {
        try {

            axios.get(`${BaseUrl.baseUrl}/city/get/${id}`).then(res => {
                if (res.data.status === 'ok') {
                    setData(res.data.data)
                    setName(res.data.data.name)
                    setCountryName(res.data.data.countryName)
                }
                else if (res.data.status === 'fail') {
                    toast('🚩' + res.data.message)
                }

            }).catch(err => {
                toast('🚨' + err.message)
            })


        } catch (err) {
            toast('🚨 Something went wrong!')
        }

    }, [])




    const submit = () => {
        

            try {

                const param={
                    name:name,
                    countryName:countryName
                }

                axios.put(`${BaseUrl.baseUrl}/city/update/${id}`,param).then(res => {
                    if (res.data.status === 'ok') {
                        toast('🎉 Updated Successfully!')
                        navigate('/city')
                    }
                    else if(res.data.status === 'fail'){
                        toast('🚩'+res.data.message)
                    }

                }).catch(err=>{
                    toast('🚨'+err.message)
                })


            } catch (err) {
                toast('🚨 Something went wrong!')
            }


    }



    return (
        <div>
            <div>
                <Header name={'City'} />
            </div>
 

            <div className='bg-light rounded-2 p-3  mb-4 me-3'>
                <div className='d-flex justify-content-between align-items-start mb-3'>
                    <h3>Edit City</h3>
                    {/* <div className='p-1 px-3 bg-warning rounded-2 d-flex justify-content-center align-items-center set-shadow' style={{cursor:'pointer'}} onClick={()=>{navigate('/addcompany')}}>
                        <h5 className='m-0 p-0'>Add</h5>
                    </div> */}
                </div>


                <div className='row  w-100'>


                    <div className='col-md-6 mb-4'>
                        <div className='border border-dark rounded-2 bg-white d-flex align-items-center px-3'>
                            <i className='bi bi-pencil'></i>
                            <input type='text' className='border-0 rounded-2 p-2 w-100 px-3' placeholder='Enter City Name' defaultValue={name} onChange={(e) => { setName(e.target.value) }} />
                        </div>
                    </div>


                    <div className='col-md-6 mb-4'>
                        <div className='border border-dark rounded-2 bg-white d-flex align-items-center px-3'>
                            <i className='bi bi-pencil'></i>
                            <input type='text' className='border-0 rounded-2 p-2 w-100 px-3' placeholder='Enter Country Name' defaultValue={countryName} onChange={(e) => { setCountryName(e.target.value) }} />
                        </div>
                    </div>



                </div>



                <div className='p-1 px-3 bg-warning rounded-2 d-flex justify-content-center align-items-center set-shadow' style={{ cursor: 'pointer', width: '140px' }} onClick={() => { submit() }}>
                    <h5 className='m-0 p-0'>Submit</h5>
                </div>

            </div>



        </div>
    )
}

export default EditCity