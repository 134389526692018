import React, { useContext, useEffect, useState } from 'react'
import Header from '../../Components/Header'
import { useNavigate, useParams } from 'react-router-dom'
import MapContext from '../../Components/MapContext';
import toast from 'react-simple-toasts';
import axios from 'axios';
import BaseUrl from '../../BaseUrl';
import Swal from 'sweetalert2';

const AddStore = () => {

    const { mapValue, setMapValue } = useContext(MapContext);

    const { cId } = useParams()

    const navigate = useNavigate()





    const [manager, setManager] = useState([])
    const [selManager, setSelManager] = useState(null)


    useEffect(() => {
        try {

            axios.get(`${BaseUrl.baseUrl}/manager/getProj/${cId}`).then(res => {
                if (res.data.status === 'ok') {
                    setManager(res.data.data)
                }
                else if (res.data.status === 'fail') {
                    toast('🚩' + res.data.message)
                }

            }).catch(err => {
                toast('🚨' + err.message)
            })


        } catch (err) {
            toast('🚨 Something went wrong!')
        }

    }, [])






    const [supervisor, setSupervisor] = useState([])
    const [selSupervisor, setSelSupervisor] = useState([])

    const handleSelectionSuperChange = (e) => {
        const options = e.target.options;
        let selectedValues = [];
        for (let i = 0; i < options.length; i++) {
            if (options[i].selected) {
                selectedValues.push(options[i].value);
            }
        }
        setSelSupervisor(selectedValues);
        console.log(selectedValues);
    };


    const getsupervisor = () => {
        try {

            axios.get(`${BaseUrl.baseUrl}/supervisor/getProj/${cId}/${selManager}`).then(res => {
                if (res.data.status === 'ok') {
                    setSupervisor(res.data.data)
                }
                else if (res.data.status === 'fail') {
                    toast('🚩' + res.data.message)
                }

            }).catch(err => {
                toast('🚨' + err.message)
            })


        } catch (err) {
            toast('🚨 Something went wrong!')
        }
    }




    const [saleman, setSaleman] = useState([])
    const [selSaleman, setSelSaleman] = useState([])

    const handleSelectionSalemanChange = (e) => {
        const options = e.target.options;
        let selectedValues = [];
        for (let i = 0; i < options.length; i++) {
            if (options[i].selected) {
                selectedValues.push(options[i].value);
            }
        }
        setSelSaleman(selectedValues);
        console.log(selectedValues);
    };


    const getSaleman = () => {
        try {
            selSupervisor.map(i => {
                axios.get(`${BaseUrl.baseUrl}/saleman/getProj/${cId}/${selManager}/${i}`).then(res => {
                    if (res.data.status === 'ok') {
                        setSaleman(prevSalemen => {
                            // Create a Set to track existing _id values
                            const existingIds = new Set(prevSalemen.map(saleman => saleman._id));
                            
                            // Filter out the new salemen who already exist in the state
                            const newSalemen = res.data.data.filter(saleman => !existingIds.has(saleman._id));
                            
                            // Return the previous state concatenated with the new unique salemen
                            return [...prevSalemen, ...newSalemen];
                        });
                    } else if (res.data.status === 'fail') {
                        toast('🚩' + res.data.message);
                    }
                }).catch(err => {
                    toast('🚨' + err.message);
                });
            });
        } catch (err) {
            toast('🚨 Something went wrong!');
        }
    };
    





    useEffect(() => {

        if (!mapValue) {
            Swal.fire({
                title: "Location",
                text: "Must first select location",
                icon: "question",
                confirmButtonText: 'Navigate',
                confirmButtonColor: "#FFC107",
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                    try {
                        navigate('/OpenMap')
                        window.location.reload()
                    } catch (error) {
                        Swal.showValidationMessage(`
        Request failed: ${error}
      `);
                    }
                },
                allowOutsideClick: () => !Swal.isLoading()

            });
        }

    }, [])




    const [name, setName] = useState(null)
    const [image, setImage] = useState(null)
    const [email, setEmail] = useState(null)
    const [phone, setPhone] = useState(null)
    const [password, setPassword] = useState(null)
    const [storeId, setStoreId] = useState(null)
    const [about, setAbout] = useState(null)


    const submit = () => {
        if (!name) {
            toast('🚨 Must enter store name!')
        }
        else if (!image) {
            toast('🚨 Must upload store image!')
        }
        else if (!selManager) {
            toast('🚨 Must select manager!')
        }
        else if (selSupervisor.length === 0) {
            toast('🚨 Must select supervisor!')
        }
        else if (selSaleman.length === 0) {
            toast('🚨 Must select saleman!')
        }
        else if (!mapValue) {
            toast('🚨 Must select location!')
        }
        else {

            try {

                const param = new FormData()
                param.append('image', image)
                param.append('name', name)
                param.append('projectId', cId)
                param.append('managerId', selManager)
                selSupervisor.map(i => {
                    param.append('supervisorId', i)
                })
                selSaleman.map(i => {
                    param.append('salemanId', i)
                })
                param.append('block', false)
                param.append('address', mapValue?.address)
                param.append('city', mapValue?.city)
                param.append('lat', mapValue?.lat)
                param.append('lng', mapValue?.lng)


                axios.post(`${BaseUrl.baseUrl}/store/create`, param).then(res => {
                    if (res.data.status === 'ok') {
                        setMapValue(null)
                        toast('🎉 Created Successfully!')
                        navigate(`/store/${cId}`)
                    }
                    else if (res.data.status === 'fail') {
                        toast('🚩' + res.data.message)
                    }

                }).catch(err => {
                    toast('🚨' + err.message)
                })


            } catch (err) {
                toast('🚨 Something went wrong!')
            }

        }

    }



    return (
        <div>
            <div>
                <Header name={'Store'} />
            </div>


            <div className='bg-light rounded-2 p-3  mb-4 me-3'>
                <div className='d-flex justify-content-between align-items-start mb-3'>
                    <h3>Add Store</h3>
                    <div className='p-1 px-3 bg-warning rounded-2 d-flex justify-content-center align-items-center set-shadow' style={{ cursor: 'pointer' }} onClick={() => { navigate(-1) }}>
                        <h5 className='m-0 p-0'>Back</h5>
                    </div>
                </div>


                <div className='row  w-100'>


                    <div className='col-md-6 mb-4'>
                        <div className='border border-dark rounded-2 bg-white d-flex align-items-center px-3'>
                            <i className='bi bi-pencil'></i>
                            <input type='text' className='border-0 rounded-2 p-2 w-100 px-3' placeholder='Enter Store Name' onChange={(e) => { setName(e.target.value) }} />
                        </div>
                    </div>

                    <div className='col-md-6 mb-4'>
                        <div className='border border-dark rounded-2 bg-white d-flex align-items-center px-3'>
                            <i className='bi bi-pencil'></i>
                            <input type='file' className='border-0 rounded-2 p-2 w-100 px-3' placeholder='Enter Logo' onChange={(e) => { setImage(e.target.files[0]) }} />
                        </div>
                    </div>


                    {mapValue ?
                        <>
                            <div className='col-md-6 mb-4'>
                                <div className='border border-dark rounded-2 bg-white d-flex align-items-center px-3'>
                                    <i className='bi bi-pencil'></i>
                                    <input type='text' className='border-0 rounded-2 p-2 w-100 px-3' placeholder='Enter Latitude' disabled defaultValue={mapValue?.lat} />
                                </div>
                            </div>


                            <div className='col-md-6 mb-4'>
                                <div className='border border-dark rounded-2 bg-white d-flex align-items-center px-3'>
                                    <i className='bi bi-pencil'></i>
                                    <input type='text' className='border-0 rounded-2 p-2 w-100 px-3' placeholder='Enter Longitude' disabled defaultValue={mapValue?.lng} />
                                </div>
                            </div>


                            <div className='col-md-6 mb-4'>
                                <div className='border border-dark rounded-2 bg-white d-flex align-items-center px-3'>
                                    <i className='bi bi-pencil'></i>
                                    <input type='text' className='border-0 rounded-2 p-2 w-100 px-3' placeholder='Enter City' disabled defaultValue={mapValue?.city} />
                                </div>
                            </div>


                            <div className='col-md-6 mb-4'>
                                <div className='border border-dark rounded-2 bg-white d-flex align-items-center px-3'>
                                    <i className='bi bi-pencil'></i>
                                    <input type='text' className='border-0 rounded-2 p-2 w-100 px-3' placeholder='Enter Address' disabled defaultValue={mapValue?.address} />
                                </div>
                            </div>

                        </>
                        : null}

                    <div className='col-md-12 mb-4'>
                        <div className='border border-dark rounded-2 bg-white d-flex align-items-center h-auto position-relative'>
                            <div className='position-absolute rounded-2 w-100 d-flex justify-content-center align-items-center' style={{ height: '120px', backgroundColor: 'rgba(0,0,0,0.5)', cursor: 'pointer' }} onClick={() => {
                                navigate('/OpenMap')
                                window.location.reload()
                            }}>
                                <h4 className='text-white'>Select Store Location</h4>
                            </div>
                            <img src={require('../../Assests/map.png')} alt='logo' className='rounded-2' style={{ width: '100%', height: '120px', objectFit: 'cover' }} />
                        </div>
                    </div>







                    <div className='col-md-6 mb-4'>

                        <div className='border border-dark rounded-2 bg-white d-flex align-items-center px-3'>
                            <select class="form-select" aria-label="Default select example" onChange={(e) => { setSelManager(e.target.value) }}>
                                <option selected>Select Manager</option>
                                {manager?.length > 0 ? manager.map(i => {
                                    return (<>
                                        <option value={i?._id}>{i?.firstName + ' ' + i?.lastName}</option>
                                    </>)
                                })

                                    : <p>No data found!</p>}

                            </select>
                        </div>
                    </div>

                    <div className='col-md-6 mb-4'>

                    </div>

                    <div className='col-md-6 mb-4'>

                        <div className='border border-dark rounded-2 bg-white d-flex align-items-center px-3' onClick={getsupervisor}>
                            <select class="form-select" aria-label="Default select example" onChange={(e) => { handleSelectionSuperChange(e) }} multiple>
                                <option >-----Select Supervisor-----</option>
                                {supervisor?.length > 0 ? supervisor.map(i => {
                                    return (<>
                                        <option value={i?._id}>{i?.firstName + ' ' + i?.lastName}</option>
                                    </>)
                                })

                                    : <p>No data found!</p>}

                            </select>
                        </div>
                    </div>



                    <div className='col-md-6 mb-4'>

                        <div className='border border-dark rounded-2 bg-white d-flex align-items-center px-3' onClick={getSaleman}>
                            <select class="form-select" aria-label="Default select example" onChange={(e) => {
                                handleSelectionSalemanChange(e)
                            }} multiple>
                                <option >-----Select Saleman-----</option>
                                {saleman?.length > 0 ? saleman.map(i => {
                                    return (<>
                                        <option value={i?._id}>{i?.firstName + ' ' + i?.lastName}</option>
                                    </>)
                                })

                                    : <p>No data found!</p>}

                            </select>
                        </div>
                    </div>



                    {/* <div className='col-md-12 mb-4'>
                        <div className='border border-dark rounded-2 bg-white d-flex align-items-center px-3 h-auto'>
                            <i className='bi bi-pencil'></i>
                            <textarea className='border-0 rounded-2 p-2 w-100 px-3' placeholder='Enter about Branch' onChange={(e) => { setAbout(e.target.value) }} />
                        </div>
                    </div> */}


                </div>



                <div className='p-1 px-3 bg-warning rounded-2 d-flex justify-content-center align-items-center set-shadow' style={{ cursor: 'pointer', width: '140px' }} onClick={() => { submit() }}>
                    <h5 className='m-0 p-0'>Submit</h5>
                </div>

            </div>



        </div>
    )
}

export default AddStore