import React, { useEffect, useState } from 'react'
import Header from '../Components/Header'
import { Bar, Line } from "react-chartjs-2";
import BaseUrl from '../BaseUrl';
import toast from 'react-simple-toasts';
import axios from 'axios';
import Chart from 'react-apexcharts'

const Home = () => {





  const [getMonth, setGetMonth] = useState([])



  useEffect(() => {
    try {

      axios.get(`${BaseUrl.baseUrl}/task/graphAllMonth`).then(res => {
        if (res.data.status === 'ok') {
          console.log(res.data);
          setGetMonth(res.data.data)
        }
        else if (res.data.status === 'fail') {
          toast('🚩' + res.data.message)
        }

      }).catch(err => {
        toast('🚨' + err.message)
      })



    } catch (err) {
      toast('🚨 Something went wrong!')
    }

  }, [])


  const [getMonthInc, setGetMonthInc] = useState([])



  useEffect(() => {
    try {

      axios.get(`${BaseUrl.baseUrl}/task/graphAllMonthInC`).then(res => {
        if (res.data.status === 'ok') {
          console.log(res.data);
          setGetMonthInc(res.data.data)
        }
        else if (res.data.status === 'fail') {
          toast('🚩' + res.data.message)
        }

      }).catch(err => {
        toast('🚨' + err.message)
      })



    } catch (err) {
      toast('🚨 Something went wrong!')
    }

  }, [])





  const options = {
    chart: {
      height: 350,
      type: 'area'
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    xaxis: {
      type: 'string',
      categories: getMonth[0]?[getMonth[0]?.label, getMonth[1]?.label, getMonth[2]?.label, getMonth[3]?.label, getMonth[4]?.label, getMonth[5]?.label, getMonth[6]?.label, getMonth[7]?.label, getMonth[8]?.label, getMonth[9]?.label, getMonth[10]?.label, getMonth[11]?.label]:[]
    },
    tooltip: {
      x: {
        format: 'MMM'
      },
    },
  }




  const series = [{
    name: 'Completed',
    color: '#F6A835',
    data: getMonth[0]?[getMonth[0]?.value, getMonth[1]?.value, getMonth[2]?.value, getMonth[3]?.value, getMonth[4]?.value, getMonth[5]?.value, getMonth[6]?.value, getMonth[7]?.value, getMonth[8]?.value, getMonth[9]?.value, getMonth[10]?.value, getMonth[11]?.value]:[]
  }, {
    name: 'InComplete',
    color: '#E54C0A',
    data: getMonthInc[0]?[getMonthInc[0]?.value, getMonthInc[1]?.value, getMonthInc[2]?.value, getMonthInc[3]?.value, getMonthInc[4]?.value, getMonthInc[5]?.value, getMonthInc[6]?.value, getMonthInc[7]?.value, getMonthInc[8]?.value, getMonthInc[9]?.value, getMonthInc[10]?.value, getMonthInc[11]?.value]:[]
  }]






  const chartData = {
    labels: [getMonth[0]?.label, getMonth[1]?.label, getMonth[2]?.label, getMonth[3]?.label, getMonth[4]?.label, getMonth[5]?.label, getMonth[6]?.label, getMonth[7]?.label, getMonth[8]?.label, getMonth[9]?.label, getMonth[10]?.label, getMonth[11]?.label],
    datasets: [
      {
        label: 'Completed',
        fill: true,
        lineTension: 0.1,
        backgroundColor: 'rgba(249, 177, 52,0.4)',
        borderColor: 'rgba(249, 177, 52,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(249, 177, 52,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(249, 177, 52,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: [getMonth[0]?.value, getMonth[1]?.value, getMonth[2]?.value, getMonth[3]?.value, getMonth[4]?.value, getMonth[5]?.value, getMonth[6]?.value, getMonth[7]?.value, getMonth[8]?.value, getMonth[9]?.value, getMonth[10]?.value, getMonth[11]?.value]
      }
    ]
  };




  const [city, setCity] = useState([])
  const [proj, setProj] = useState([])
  const [empl, setEmpl] = useState([])
  const [projData, setProjData] = useState([])
  const [emplData, setEmplData] = useState([])



  useEffect(() => {
    try {

      axios.get(`${BaseUrl.baseUrl}/employe/filter`).then(res => {
        if (res.data.status === 'ok') {
          setEmplData(res.data.data)
        }
        else if (res.data.status === 'fail') {
          toast('🚩' + res.data.message)
        }

      }).catch(err => {
        toast('🚨' + err.message)
      })



      axios.get(`${BaseUrl.baseUrl}/project/filter`).then(res => {
        if (res.data.status === 'ok') {
          setProjData(res.data.data)
        }
        else if (res.data.status === 'fail') {
          toast('🚩' + res.data.message)
        }

      }).catch(err => {
        toast('🚨' + err.message)
      })



      axios.get(`${BaseUrl.baseUrl}/city/get`).then(res => {
        if (res.data.status === 'ok') {
          setCity(res.data.data)
        }
        else if (res.data.status === 'fail') {
          toast('🚩' + res.data.message)
        }

      }).catch(err => {
        toast('🚨' + err.message)
      })




      axios.get(`${BaseUrl.baseUrl}/project/get`).then(res => {
        if (res.data.status === 'ok') {
          setProj(res.data.data)
        }
        else if (res.data.status === 'fail') {
          toast('🚩' + res.data.message)
        }

      }).catch(err => {
        toast('🚨' + err.message)
      })



      axios.get(`${BaseUrl.baseUrl}/employe/get`).then(res => {
        if (res.data.status === 'ok') {
          setEmpl(res.data.data)
        }
        else if (res.data.status === 'fail') {
          toast('🚩' + res.data.message)
        }

      }).catch(err => {
        toast('🚨' + err.message)
      })



    } catch (err) {
      toast('🚨 Something went wrong!')
    }

  }, [])





  return (
    <div>

      <div>
        <Header name={'Dashboard'} />
      </div>


      {/* <div className=' bg-warning border border-dark set-shadow p-3 d-flex justify-content-between align-items-center rounded-2 gap-2 mb-3 px-5 ps-3 mb-4 me-3'  >
        <p>Welcome to ProShelf Panel! Below you'll see your tasks details for this company. To view your total number of tasks, simply navigate to the Tasks tab on the left.</p>
      </div> */}



      <div className='row w-100 mb-4'>

        <div className='col-md-4 mb-3'>
          <div className='bg-warning rounded-2 set-shadow p-3 d-flex justify-content-between align-items-center'>
            <div>
              <i className='bi bi-stack fs-2 '></i>
            </div>
            <div>
              <h5 className=''>Total Projects</h5>
              <h5 className='text-center'>{proj?.length}</h5>
            </div>
          </div>
        </div>

        <div className='col-md-4 mb-3'>
          <div className='bg-warning rounded-2 set-shadow p-3 d-flex justify-content-between align-items-center'>
            <div>
              <i className='bi bi-building fs-2 '></i>
            </div>
            <div>
              <h5 className=''>Total Cities</h5>
              <h5 className='text-center'>{city?.length}</h5>
            </div>
          </div>
        </div>


        <div className='col-md-4 mb-3'>
          <div className='bg-warning rounded-2 set-shadow p-3 d-flex justify-content-between align-items-center'>
            <div>
              <i className='bi bi-people-fill fs-2 '></i>
            </div>
            <div>
              <h5 className=''>Total Team Members</h5>
              <h5 className='text-center'>{empl?.length}</h5>
            </div>
          </div>
        </div>


      </div>



      <div className='bg-light rounded-2 p-3  mb-4 me-3'>
        <div className='d-flex justify-content-between align-items-start mb-3'>
          <h3>Project</h3>
          {/* <div className='p-1 px-3 bg-warning rounded-2 d-flex justify-content-center align-items-center set-shadow' style={{ cursor: 'pointer' }} onClick={() => { navigate('/addproject') }}>
                        <h5 className='m-0 p-0'>Add</h5>
                    </div> */}
        </div>


        <div className='border border-dark rounded-2 p-3 w-100'>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Project</th>
                  <th scope="col">Total No Stores</th>
                  <th scope="col">Completed</th>
                  <th scope="col">InComplete</th>
                  <th scope="col">Cancelled</th>
                </tr>
              </thead>
              <tbody>

                {projData?.length > 0 ? projData?.map(i => {
                  return (<>

                    <tr>
                      <td>{i?.name}</td>
                      <td>{i?.store}</td>
                      <td>{i?.completed}</td>
                      <td>{i?.incompleted}</td>
                      <td>{i?.cancelled}</td>
                    </tr>

                  </>)
                }) :
                  <p>No data found!</p>
                }

              </tbody>
            </table>
          </div>
        </div>

      </div>


      <div className='bg-light rounded-2 p-3  mb-4 me-3'>
        <div className='d-flex justify-content-between align-items-start mb-3'>
          <h3>Our Team</h3>
          {/* <div className='p-1 px-3 bg-warning rounded-2 d-flex justify-content-center align-items-center set-shadow' style={{ cursor: 'pointer' }} onClick={() => { navigate('/addproject') }}>
                        <h5 className='m-0 p-0'>Add</h5>
                    </div> */}
        </div>


        <div className='border border-dark rounded-2 p-3 w-100'>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Total No Tasks</th>
                  <th scope="col">Completed</th>
                  <th scope="col">InComplete</th>
                  <th scope="col">Cancelled</th>
                </tr>
              </thead>
              <tbody>

                {emplData?.length > 0 ? emplData?.map(i => {
                  return (<>

                    <tr>
                      <td>{i?.firstName+" "+i?.lastName}</td>
                      <td>{i?.store}</td>
                      <td>{i?.completed}</td>
                      <td>{i?.incompleted}</td>
                      <td>{i?.cancelled}</td>
                    </tr>

                  </>)
                }) :
                  <p>No data found!</p>
                }

              </tbody>
            </table>
          </div>
        </div>

      </div>


      <div className='bg-light rounded-2 p-3  mb-4 me-3'>
        <h3>Monthly Report</h3>
        {/* <Bar data={chartData} options={{
                    indexAxis: 'y',
                    responsive: true,
                    scales: {
                        x: {
                            beginAtZero: true,
                        },
                    },
                }} /> */}

        {/* <Line data={chartData}  /> */}

        <Chart options={options} series={series} type="area" height={380} width={'100%'} />


      </div>



    </div>
  )
}

export default Home