import React, { useEffect, useState } from "react";
import Header from "../../Components/Header";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BaseUrl from "../../BaseUrl";
import toast from "react-simple-toasts";
import Swal from "sweetalert2";
import moment from "moment";

const Reports = () => {

  const myState = [
    {
      name: 'Pending',
      value: 'pending'
    },
    {
      name: 'Completed',
      value: 'Completed'
    },
    {
      name: 'InComplete',
      value: 'Incomplete'
    },

  ]




  const [selState,setSelState]=useState(null)



  const myStatus = [
    {
      name: 'Block',
      value: true
    },
    {
      name: 'Approved',
      value: false
    },

  ]

  const [selStatus,setSelStatus]=useState(null)

  const navigate = useNavigate();

  const [active, setActive] = useState('Pending');

  const [data, setData] = useState([]);

  useEffect(() => {
    try {
      axios
        .get(`${BaseUrl.baseUrl}/task/get`)
        .then((res) => {
          console.log(res.data);
          if (res.data.status === "ok") {
            setData(res.data.data);
          } else if (res.data.status === "fail") {
            toast("🚩" + res.data.message);
          }
        })
        .catch((err) => {
          toast("🚨" + err.message);
        });
    } catch (err) {
      toast("🚨 Something went wrong!");
    }
  }, []);






  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)


  const [project, setProject] = useState([])
  const [projectId, setProjectId] = useState(null)


  useEffect(() => {
    try {

      axios.get(`${BaseUrl.baseUrl}/project/get`).then(res => {
        if (res.data.status === 'ok') {
          setProject(res.data.data)
        }
        else if (res.data.status === 'fail') {
          toast('🚩' + res.data.message)
        }

      }).catch(err => {
        toast('🚨' + err.message)
      })


    } catch (err) {
      toast('🚨 Something went wrong!')
    }

  }, [])




  const [store, setStore] = useState([])
  const [storeId, setStoreId] = useState(null)
  const [city, setCity] = useState(null)


  useEffect(() => {
    try {

      axios.get(`${BaseUrl.baseUrl}/store/get`).then(res => {
        if (res.data.status === 'ok') {
          setStore(res.data.data)
        }
        else if (res.data.status === 'fail') {
          toast('🚩' + res.data.message)
        }

      }).catch(err => {
        toast('🚨' + err.message)
      })


    } catch (err) {
      toast('🚨 Something went wrong!')
    }

  }, [])




  const [team, setTeam] = useState([])
  const [teamId, setTeamId] = useState(null)


  useEffect(() => {
    try {

      axios.get(`${BaseUrl.baseUrl}/employe/get`).then(res => {
        if (res.data.status === 'ok') {
          setTeam(res.data.data)
        }
        else if (res.data.status === 'fail') {
          toast('🚩' + res.data.message)
        }

      }).catch(err => {
        toast('🚨' + err.message)
      })


    } catch (err) {
      toast('🚨 Something went wrong!')
    }

  }, [])




  const filterdata = async () => {
    try {

      axios.get(`${BaseUrl.baseUrl}/task/filter/${storeId}/${city}/${projectId}/${teamId}/${selState}/${selStatus}/${startDate}/${endDate}`).then(res => {
        if (res.data.status === 'ok') {
          setData(res.data.data)
        }
        else if (res.data.status === 'fail') {
          toast('🚩' + res.data.message)
        }

      }).catch(err => {
        toast('🚨' + err.message)
      })


    } catch (err) {
      toast('🚨 Something went wrong!')
    }
  }


  const del = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          axios
            .delete(`${BaseUrl.baseUrl}/task/delete/${id}`)
            .then((res) => {
              if (res.data.status === "ok") {
                toast("🎉 Deleted Successfully!");

                axios
                  .get(`${BaseUrl.baseUrl}/task/get`)
                  .then((res) => {
                    if (res.data.status === "ok") {
                      setData(res.data.data);
                    } else if (res.data.status === "fail") {
                      toast("🚩" + res.data.message);
                    }
                  })
                  .catch((err) => {
                    toast("🚨" + err.message);
                  });
              } else if (res.data.status === "fail") {
                toast("🚩" + res.data.message);
              }
            })
            .catch((err) => {
              toast("🚨" + err.message);
            });
        } catch (err) {
          toast("🚨 Something went wrong!");
        }
      }
    });
  };



  return (
    <div>
      <div>
        <Header name={"Reports"} />
      </div>


      <div className="d-flex justify-content-between align-items-center p-3">
        <div></div>
        <div className="d-flex align-items-center gap-3 flex-wrap">


          <div className='shadow-sm  rounded-2 bg-white d-flex align-items-center px-3'>
            <select class="form-select" aria-label="Default select example" onChange={(e) => { setStoreId(e.target.value) }}>
              <option selected>Select Store</option>
              {store?.length > 0 ? store.map(i => {
                return (<>
                  <option value={i?._id}>{i?.name}</option>
                </>)
              })

                : <p>No data found!</p>}

            </select>
          </div>


          <div className='shadow-sm rounded-2 bg-white d-flex align-items-center px-3'>
            <select class="form-select" aria-label="Default select example" onChange={(e) => { setCity(e.target.value) }}>
              <option selected>Select City</option>
              {store?.length > 0 ?
                [...new Set(store.map(i => i.city))].map(city => (
                  <option key={city} value={city}>{city}</option>
                )) :
                <p>No data found!</p>}
            </select>
          </div>



          <div className='shadow-sm rounded-2 bg-white d-flex align-items-center px-3'>
            <select class="form-select" aria-label="Default select example" onChange={(e) => { setProjectId(e.target.value) }}>
              <option selected>Select Project</option>
              {project?.length > 0 ? project.map(i => {
                return (<>
                  <option value={i?._id}>{i?.name}</option>
                </>)
              })

                : <p>No data found!</p>}

            </select>
          </div>
          <div className='shadow-sm rounded-2 bg-white d-flex align-items-center px-3'>
            <select class="form-select" aria-label="Default select example" onChange={(e) => { setTeamId(e.target.value) }}>
              <option selected>Select Team</option>
              {team?.length > 0 ? team.map(i => {
                return (<>
                  <option value={i?._id}>{i?.firstName + " " + i?.lastName}</option>
                </>)
              })

                : <p>No data found!</p>}

            </select>
          </div>

          <div className='shadow-sm rounded-2 bg-white d-flex align-items-center px-3'>
            <select class="form-select" aria-label="Default select example" onChange={(e) => { setSelState(e.target.value) }}>
              <option selected>Select State</option>
              {myState?.length > 0 ? myState.map(i => {
                return (<>
                  <option value={i?.value}>{i?.name}</option>
                </>)
              })

                : <p>No data found!</p>}

            </select>
          </div>

          <div className='shadow-sm rounded-2 bg-white d-flex align-items-center px-3'>
            <select class="form-select" aria-label="Default select example" onChange={(e) => { setSelStatus(e.target.value) }}>
              <option selected>Select Status</option>
              {myStatus?.length > 0 ? myStatus.map(i => {
                return (<>
                  <option value={i?.value}>{i?.name==='Block'?'Pending':i?.name}</option>
                </>)
              })

                : <p>No data found!</p>}

            </select>
          </div>


          <div className='shadow-sm rounded-2 bg-white d-flex align-items-center px-2'>
            <input type='date' className='border-0 rounded-2 p-2 w-100 px-3' placeholder='Select Start Date' onChange={(e) => { setStartDate(e.target.value) }} />
          </div>

          <div className='shadow-sm rounded-2 bg-white d-flex align-items-center px-2'>
            <input type='date' className='border-0 rounded-2 p-2 w-100 px-3' placeholder='Select Start Date' onChange={(e) => { setEndDate(e.target.value) }} />
          </div>

          <div className='shadow-sm rounded-2 bg-success d-flex align-items-center'>
            <button className='border-0 rounded-2 p-2 w-100 px-3 bg-warning' onClick={filterdata}>
              Submit
            </button>
          </div>
        </div>
      </div>

      <div className="bg-light rounded-2 p-3  mb-4 me-3">
        <div className="d-flex justify-content-between align-items-start mb-3">
          <h3>Reports</h3>
          {/* <div
            className="p-1 px-3 bg-warning rounded-2 d-flex justify-content-center align-items-center set-shadow-sm"
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/addproduct");
            }}
          >
            <h5 className="m-0 p-0">Add</h5>
          </div> */}
        </div>




        <div className="border border-dark rounded-2 p-3 w-100">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Store Name</th>
                  {/* <th scope="col">Scoring</th> */}
                  <th scope="col">City</th>
                  <th scope="col">Project</th>
                  <th scope="col">Assign To</th>
                  <th scope="col">Score</th>
                  <th scope="col">Last Date</th>
                  <th scope="col">Status</th>
                  <th scope="col">State</th>
                </tr>
              </thead>
              <tbody>
                {data?.length > 0 ? (
                  data?.map((i) => {
                    return (
                      <>
                        <tr>
                          <td>{i?.storeId?.name}</td>
                          {/* <td>20</td> */}
                          <td>{i?.storeId?.city}</td>
                          <td>{i?.projectId?.name}</td>
                          <td>{i?.assignTo?.firstName}</td>
                          <td><span className="badge bg-warning text-black">{`${i?.givenScore?i?.givenScore:0}/${i?.totalScore?i?.totalScore:0}`}</span></td>
                          <td>{moment(i?.lastDate).format('YYYY-MM-DD')}</td>
                          <td>{i?.block?<span className="badge bg-danger">Pending</span>:<span className="badge bg-success">Approved</span>}</td>
                          <td>{i?.state}</td>
                          <td>
                            <div className="d-flex align-items-center gap-3">
                              <a
                                className="btn btn-outline-warning btn-sm"
                                href={`/viewreport/${i?._id}`}
                                // onClick={() => {
                                //   navigate(`/viewreport/${i?._id}`);
                                // }}
                              >
                                <i className="bi bi-file-earmark-easel-fill"></i>
                              </a>

                              <button
                                title="Delete"
                                className="btn btn-outline-danger btn-sm"
                                onClick={() => {
                                  del(i?._id);
                                }}
                              >
                                <i className="bi bi-trash-fill"></i>
                              </button>
                            </div>
                          </td>
                          
                        </tr>
                      </>
                    );
                  })
                ) : (
                  <p>No data found!</p>
                )}
              </tbody>
            </table>
          </div>
        </div>





      </div>
    </div>
  );
};

export default Reports;
