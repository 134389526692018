import React, { useContext, useEffect, useState } from 'react'
import Header from '../../Components/Header'
import { useNavigate, useParams } from 'react-router-dom'
import toast from 'react-simple-toasts';
import axios from 'axios';
import BaseUrl from '../../BaseUrl';

const AddTasks = () => {



    const navigate = useNavigate()

    const { cId, sId } = useParams()




    const [branchData, setBranchData] = useState([])


    useEffect(() => {
        try {

            axios.get(`${BaseUrl.baseUrl}/branch/get`).then(res => {
                if (res.data.status === 'ok') {
                    setBranchData(res.data.data)
                }
                else if (res.data.status === 'fail') {
                    toast('🚩' + res.data.message)
                }

            }).catch(err => {
                toast('🚨' + err.message)
            })


        } catch (err) {
            toast('🚨 Something went wrong!')
        }

    }, [])



    const [assignData, setAssignData] = useState([])


    useEffect(() => {
        try {

            axios.get(`${BaseUrl.baseUrl}/employe/get`).then(res => {
                if (res.data.status === 'ok') {
                    setAssignData(res.data.data)
                }
                else if (res.data.status === 'fail') {
                    toast('🚩' + res.data.message)
                }

            }).catch(err => {
                toast('🚨' + err.message)
            })


        } catch (err) {
            toast('🚨 Something went wrong!')
        }

    }, [])





    const [name, setName] = useState(null)
    const [lastDate, setLastDate] = useState(null)
    const [branchId, setBranchId] = useState(null)
    const [assignId, setAssignId] = useState(null)


    const submit = () => {
        if (!lastDate) {
            toast('🚨 Must select last date!')
        }
        else if (!assignId) {
            toast('🚨 Must select assign to!')
        }
        else {

            try {

                const newSid = JSON.parse(sId)


                if (typeof (newSid) === 'object') {

                    newSid.map((i, index) => {

                        const param = new FormData()
                        param.append('projectId', cId)
                        param.append('lastDate', lastDate.toString())
                        param.append('storeId', i)
                        param.append('assignTo', assignId)

                        axios.post(`${BaseUrl.baseUrl}/task/create`, param).then(res => {
                            console.log(res);
                            if (res.data.status === 'ok') {

                            }
                            else if (res.data.status === 'fail') {
                                toast('🚩' + res.data.message)
                            }

                        }).catch(err => {
                            toast('🚨' + err.message)
                        })

                        if (newSid?.length === index + 1) {
                            toast('🎉 Created Successfully!')
                            navigate(-1)
                        }


                    })

                }
                else {


                    const param = new FormData()
                    param.append('projectId', cId)
                    param.append('lastDate', lastDate.toString())
                    param.append('storeId', newSid)
                    param.append('assignTo', assignId)

                    axios.post(`${BaseUrl.baseUrl}/task/create`, param).then(res => {
                        console.log(res);
                        if (res.data.status === 'ok') {
                            toast('🎉 Created Successfully!')
                            navigate(-1)
                        }
                        else if (res.data.status === 'fail') {
                            toast('🚩' + res.data.message)
                        }

                    }).catch(err => {
                        toast('🚨' + err.message)
                    })



                }

            } catch (err) {
                toast('🚨 Something went wrong!')
            }

        }

    }



    return (
        <div>
            <div>
                <Header name={'Tasks'} />
            </div>


            <div className='bg-light rounded-2 p-3  mb-4 me-3'>
                <div className='d-flex justify-content-between align-items-start mb-3'>
                    <h3>Add Task</h3>
                    <div className='p-1 px-3 bg-warning rounded-2 d-flex justify-content-center align-items-center set-shadow' style={{ cursor: 'pointer' }} onClick={() => { navigate(-1) }}>
                        <h5 className='m-0 p-0'>Back</h5>
                    </div>
                </div>


                <div className='row  w-100'>




                    <div className='col-md-6 mb-4'>
                        <div className='border border-dark rounded-2 bg-white d-flex align-items-center px-3'>
                            <i className='bi bi-pencil'></i>
                            <input type='date' className='border-0 rounded-2 p-2 w-100 px-3' placeholder='Select Last Date' onChange={(e) => { setLastDate(e.target.value) }} />
                        </div>
                    </div>



                    <div className='col-md-6 mb-4'>

                        <div className='border border-dark rounded-2 bg-white d-flex align-items-center px-3'>
                            <select class="form-select" aria-label="Default select example" onChange={(e) => { setAssignId(e.target.value) }}>
                                <option selected>Select Assign To</option>
                                {assignData?.length > 0 ? assignData.map(i => {
                                    return (<>
                                        <option value={i?._id}>{i?.firstName}</option>
                                    </>)
                                })

                                    : <p>No data found!</p>}

                            </select>
                        </div>
                    </div>




                </div>


                <div className='p-1 px-3 mb-5 bg-warning rounded-2 d-flex justify-content-center align-items-center set-shadow ' style={{ cursor: 'pointer', width: '140px' }} onClick={() => { submit() }}>
                    <h5 className='m-0 p-0'>Submit</h5>
                </div>



            </div>




        </div>
    )
}

export default AddTasks