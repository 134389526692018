import React, { useEffect, useState } from "react";
import Header from "../../Components/Header";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BaseUrl from "../../BaseUrl";
import toast from "react-simple-toasts";
import Swal from "sweetalert2";
import { Line } from 'react-chartjs-2';
import Chart from 'react-apexcharts'

const Performances = () => {
  const navigate = useNavigate();





  const [branchData, setBranchData] = useState([])
  const [branchId, setBranchId] = useState(null)
  const [startDate, setStartDate] = useState(null)


  useEffect(() => {
    try {

      axios.get(`${BaseUrl.baseUrl}/branch/get`).then(res => {
        if (res.data.status === 'ok') {
          setBranchData(res.data.data)
        }
        else if (res.data.status === 'fail') {
          toast('🚩' + res.data.message)
        }

      }).catch(err => {
        toast('🚨' + err.message)
      })


    } catch (err) {
      toast('🚨 Something went wrong!')
    }

  }, [])





  const [getMonth, setGetMonth] = useState([])




  useEffect(() => {
    try {

      axios.get(`${BaseUrl.baseUrl}/task/graphAllMonth`).then(res => {
        if (res.data.status === 'ok') {
          console.log(res.data);
          setGetMonth(res.data.data)
        }
        else if (res.data.status === 'fail') {
          toast('🚩' + res.data.message)
        }

      }).catch(err => {
        toast('🚨' + err.message)
      })



    } catch (err) {
      toast('🚨 Something went wrong!')
    }

  }, [])


  const [getMonthInc, setGetMonthInc] = useState([])



  useEffect(() => {
    try {

      axios.get(`${BaseUrl.baseUrl}/task/graphAllMonthInC`).then(res => {
        if (res.data.status === 'ok') {
          console.log(res.data);
          setGetMonthInc(res.data.data)
        }
        else if (res.data.status === 'fail') {
          toast('🚩' + res.data.message)
        }

      }).catch(err => {
        toast('🚨' + err.message)
      })



    } catch (err) {
      toast('🚨 Something went wrong!')
    }

  }, [])




  const options = {
    chart: {
      height: 350,
      type: 'area'
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    xaxis: {
      type: 'string',
      categories: getMonth[0]?[getMonth[0]?.label, getMonth[1]?.label, getMonth[2]?.label, getMonth[3]?.label, getMonth[4]?.label, getMonth[5]?.label, getMonth[6]?.label, getMonth[7]?.label, getMonth[8]?.label, getMonth[9]?.label, getMonth[10]?.label, getMonth[11]?.label]:[]
    },
    tooltip: {
      x: {
        format: 'MMM'
      },
    },
  }




  const series = [{
    name: 'Completed',
    color: '#F6A835',
    data: getMonth[0]?[getMonth[0]?.value, getMonth[1]?.value, getMonth[2]?.value, getMonth[3]?.value, getMonth[4]?.value, getMonth[5]?.value, getMonth[6]?.value, getMonth[7]?.value, getMonth[8]?.value, getMonth[9]?.value, getMonth[10]?.value, getMonth[11]?.value]:[]
  }, {
    name: 'InComplete',
    color: '#E54C0A',
    data: getMonthInc[0]?[getMonthInc[0]?.value, getMonthInc[1]?.value, getMonthInc[2]?.value, getMonthInc[3]?.value, getMonthInc[4]?.value, getMonthInc[5]?.value, getMonthInc[6]?.value, getMonthInc[7]?.value, getMonthInc[8]?.value, getMonthInc[9]?.value, getMonthInc[10]?.value, getMonthInc[11]?.value]:[]
  }]








  const chartData = {
    labels: [getMonth[0]?.label, getMonth[1]?.label, getMonth[2]?.label, getMonth[3]?.label, getMonth[4]?.label, getMonth[5]?.label, getMonth[6]?.label, getMonth[7]?.label, getMonth[8]?.label, getMonth[9]?.label, getMonth[10]?.label, getMonth[11]?.label],
    datasets: [
      {
        label: 'Completed',
        fill: true,
        lineTension: 0.1,
        backgroundColor: 'rgba(249, 177, 52,0.4)',
        borderColor: 'rgba(249, 177, 52,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(249, 177, 52,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(249, 177, 52,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: [getMonth[0]?.value, getMonth[1]?.value, getMonth[2]?.value, getMonth[3]?.value, getMonth[4]?.value, getMonth[5]?.value, getMonth[6]?.value, getMonth[7]?.value, getMonth[8]?.value, getMonth[9]?.value, getMonth[10]?.value, getMonth[11]?.value]
      }
    ]
  };


  const [getWeek, setGetWeek] = useState([])



  useEffect(() => {
    try {

      axios.get(`${BaseUrl.baseUrl}/task/graphAllWeek`).then(res => {
        if (res.data.status === 'ok') {
          console.log(res.data);
          setGetWeek(res.data.data)
        }
        else if (res.data.status === 'fail') {
          toast('🚩' + res.data.message)
        }

      }).catch(err => {
        toast('🚨' + err.message)
      })



    } catch (err) {
      toast('🚨 Something went wrong!')
    }

  }, [])




  const chartData1 = {
    labels: [getWeek[0]?.label, getWeek[1]?.label, getWeek[2]?.label, getWeek[3]?.label, getWeek[4]?.label, getWeek[5]?.label, getWeek[6]?.label],
    datasets: [
      {
        label: 'Completed',
        fill: true,
        lineTension: 0.1,
        backgroundColor: 'rgba(249, 177, 52,0.4)',
        borderColor: 'rgba(249, 177, 52,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(249, 177, 52,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(249, 177, 52,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: [getWeek[0]?.value, getWeek[1]?.value, getWeek[2]?.value, getWeek[3]?.value, getWeek[4]?.value, getWeek[5]?.value, getWeek[6]?.value]
      }
    ]
  };









  return (
    <div>
      <div>
        <Header name={"Performances"} />
      </div>

      <div className='bg-light rounded-2 p-3  mb-4 me-3'>
        <h3>Team Performances</h3>
        <Chart options={options} series={series} type="area" height={380} width={'100%'} />
      </div>

      {/* <div className='bg-light rounded-2 p-3  mb-4 me-3'>
        <h3>Monthly Performances</h3>
        <Line data={chartData}  />
      </div> */}
    </div>
  );
};

export default Performances;
