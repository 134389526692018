import React, { useContext, useEffect, useState } from "react";
import Header from "../../Components/Header";
import { useNavigate, useParams } from "react-router-dom";
import MapContext from "../../Components/MapContext";
import toast from "react-simple-toasts";
import axios from "axios";
import BaseUrl from "../../BaseUrl";
import Swal from "sweetalert2";

const EditQuestion = () => {
  const navigate = useNavigate();
  const { id, taskId } = useParams();




  const questType = ['Text', 'Number', 'Radio Button', 'Checkbox']



  const [questionName, setQuestionName] = useState(null)
  const [questionType, setQuestionType] = useState(null)


  const [data, setData] = useState({});

  useEffect(() => {
    try {
      axios
        .get(`${BaseUrl.baseUrl}/questionAll/get/${id}`)
        .then((res) => {
          if (res.data.status === "ok") {
            setData(res.data.data);
            setQuestionName(res.data.data.name);
            setQuestionType(res.data.data.type);
          } else if (res.data.status === "fail") {
            toast("🚩" + res.data.message);
          }
        })
        .catch((err) => {
          toast("🚨" + err.message);
        });
    } catch (err) {
      toast("🚨 Something went wrong!");
    }
  }, []);




  const submit = () => {
    try {
      const param = new FormData();

      if (questionName !== data?.name) {
        param.append("name", questionName);
      }
      if (questionType !== data?.type) {
        param.append("type", questionType);
      }

      axios
        .put(`${BaseUrl.baseUrl}/questionAll/update/${id}`, param)
        .then((res) => {
          if (res.data.status === "ok") {
            toast("🎉 Updated Successfully!");
            navigate(-1);
          } else if (res.data.status === "fail") {
            toast("🚩" + res.data.message);
          }
        })
        .catch((err) => {
          toast("🚨" + err.message);
        });
    } catch (err) {
      toast("🚨 Something went wrong!");
    }
  };

  return (
    <div>
      <div>
        <Header name={"Type"} />
      </div>

      <div className="bg-light rounded-2 p-3  mb-4 me-3">
        <div className="d-flex justify-content-between align-items-start mb-3">
          <h3>Edit Type</h3>
          <div className='p-1 px-3 bg-warning rounded-2 d-flex justify-content-center align-items-center set-shadow' style={{ cursor: 'pointer' }} onClick={() => { navigate(-1) }}>
            <h5 className='m-0 p-0'>Back</h5>
          </div>
        </div>

        <div className='row  w-100'>


          <div className='col-md-12 mb-4'>
            <p>Select Question Type</p>
            <div className='d-flex align-items-center px-3 gap-3'>
              {questType?.map(i => {
                return (<>

                  <div className='d-flex align-items-center px-3 gap-3' onClick={() => { setQuestionType(i) }} style={{ cursor: 'pointer' }} >
                    <div style={{ width: '20px', height: '20px', borderRadius: 200, border: questionType === i ? '5px solid gold' : '1px solid black' }} />
                    <p>{i}</p>

                  </div>

                </>)
              })}
            </div>
          </div>

          <div className='col-md-12 mb-4'>
            <div className='border border-dark rounded-2 bg-white d-flex align-items-center px-3'>
              <i className='bi bi-pencil'></i>
              <input type='text' className='border-0 rounded-2 p-2 w-100 px-3' placeholder='Enter Question Name' defaultValue={questionName} onChange={(e) => { setQuestionName(e.target.value) }} />
            </div>
          </div>




        </div>

        <div
          className="p-1 px-3 bg-warning rounded-2 d-flex justify-content-center align-items-center set-shadow"
          style={{ cursor: "pointer", width: "140px" }}
          onClick={() => {
            submit();
          }}
        >
          <h5 className="m-0 p-0">Submit</h5>
        </div>
      </div>
    </div>
  );
};

export default EditQuestion;
