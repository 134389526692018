import React, { useContext, useEffect, useState } from "react";
import Header from "../../Components/Header";
import { useNavigate, useParams } from "react-router-dom";
import MapContext from "../../Components/MapContext";
import toast from "react-simple-toasts";
import axios from "axios";
import BaseUrl from "../../BaseUrl";
import Swal from "sweetalert2";

const AddSupervisor = () => {
  const navigate = useNavigate();

  const {cId}=useParams()

  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [image, setImage] = useState(null);
  const [phone, setPhone] = useState(null);
  const [password, setPassword] = useState(null);
  const [cofirmPassword, setConfirmPassword] = useState(null);
  const [username, setUsername] = useState(null);





  
  const [manager, setManager] = useState([])
  const [selManager, setSelManager] = useState(null)


  useEffect(() => {
      try {

          axios.get(`${BaseUrl.baseUrl}/manager/getProj/${cId}`).then(res => {
              if (res.data.status === 'ok') {
                  setManager(res.data.data)
              }
              else if (res.data.status === 'fail') {
                  toast('🚩' + res.data.message)
              }

          }).catch(err => {
              toast('🚨' + err.message)
          })


      } catch (err) {
          toast('🚨 Something went wrong!')
      }

  }, [])

  
  const [country, setCountry] = useState([])
  const [selCountry, setSelCountry] = useState(null)


  useEffect(() => {
      try {

          axios.get(`${BaseUrl.baseUrl}/city/get`).then(res => {
              if (res.data.status === 'ok') {
                  setCountry(res.data.data)
              }
              else if (res.data.status === 'fail') {
                  toast('🚩' + res.data.message)
              }

          }).catch(err => {
              toast('🚨' + err.message)
          })


      } catch (err) {
          toast('🚨 Something went wrong!')
      }

  }, [])


  const [city, setCity] = useState([])
  const [selCity, setSelCity] = useState([])


  const getcity=() => {
      try {

          axios.get(`${BaseUrl.baseUrl}/city/country/${selCountry}`).then(res => {
              if (res.data.status === 'ok') {
                  setCity(res.data.data)
              }
              else if (res.data.status === 'fail') {
                  toast('🚩' + res.data.message)
              }

          }).catch(err => {
              toast('🚨' + err.message)
          })


      } catch (err) {
          toast('🚨 Something went wrong!')
      }

  }







  const submit = () => {
    if (!firstName) {
      toast("🚨 Must enter first name!");
    }
    else if (!lastName) {
      toast("🚨 Must enter last name!");
    }
    else if (!selCountry) {
      toast("🚨 Must select country!");
    }
    else if (!selCity) {
      toast("🚨 Must select city!");
    }
    else if (!email) {
      toast("🚨 Must enter email!");
    } 
    else if (!phone) {
      toast("🚨 Must enter phone!");
    } 
    else if (!selManager) {
      toast("🚨 Must select Manager!");
    } 
    else {
      try {
        

        const param={
          "projectId": cId,
          "managerId": selManager,
          "firstName": firstName,
          "lastName": lastName,
          "email": email,
          "phone": phone,
          "country": selCountry,
          "city": selCity
        }

        axios
          .post(`${BaseUrl.baseUrl}/supervisor/create`, param)
          .then((res) => {
            if (res.data.status === "ok") {
              toast("🎉 Created Successfully!");
              navigate(`/supervisor/${cId}`);
            } else if (res.data.status === "fail") {
              toast("🚩" + res.data.message);
            }
          })
          .catch((err) => {
            toast("🚨" + err.message);
          });
      } catch (err) {
        toast("🚨 Something went wrong!");
      }
    }
  };


  return (
    <div>
      <div>
        <Header name={"Supervisor"} />
      </div>

      <div className="bg-light rounded-2 p-3  mb-4 me-3">
        <div className="d-flex justify-content-between align-items-start mb-3">
          <h3>Add Supervisor</h3>
          <div className='p-1 px-3 bg-warning rounded-2 d-flex justify-content-center align-items-center set-shadow' style={{ cursor: 'pointer' }} onClick={() => { navigate(-1) }}>
                        <h5 className='m-0 p-0'>Back</h5>
                    </div>
        </div>

        <div className="row  w-100">

          <div className="col-md-6 mb-4">
            <div className="border border-dark rounded-2 bg-white d-flex align-items-center px-3">
              <i className="bi bi-pencil"></i>
              <input
                type="text"
                className="border-0 rounded-2 p-2 w-100 px-3"
                placeholder="Enter First Name"
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
              />
            </div>
          </div>

          <div className="col-md-6 mb-4">
            <div className="border border-dark rounded-2 bg-white d-flex align-items-center px-3">
              <i className="bi bi-pencil"></i>
              <input
                type="text"
                className="border-0 rounded-2 p-2 w-100 px-3"
                placeholder="Enter Last Name"
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
              />
            </div>
          </div>

          <div className='col-md-6 mb-4'>

            <div className='border border-dark rounded-2 bg-white d-flex align-items-center px-3'>
              <select class="form-select" aria-label="Default select example" onChange={(e) => { setSelManager(e.target.value) }}>
                <option selected>Select Manager</option>
                {manager?.length > 0 ? manager.map(i => {
                  return (<>
                    <option value={i?._id}>{i?.firstName+' '+i?.lastName}</option>
                  </>)
                })

                  : <p>No data found!</p>}

              </select>
            </div>
          </div>


          <div className='col-md-6 mb-4'>

            <div className='border border-dark rounded-2 bg-white d-flex align-items-center px-3'>
              <select class="form-select" aria-label="Default select example" onChange={(e) => { setSelCountry(e.target.value) }}>
                <option selected>Select Country</option>
                {country?.length > 0 ? [...new Set(country.map(i => i?.country))].map(i => {
                  return (<>
                    <option value={i}>{i}</option>
                  </>)
                })

                  : <p>No data found!</p>}

              </select>
            </div>
          </div>

          <div className='col-md-6 mb-4'>

            <div className='border border-dark rounded-2 bg-white d-flex align-items-center px-3' onClick={getcity}>
              <select class="form-select" aria-label="Default select example" onChange={(e) => { setSelCity(e.target.value) }}>
                <option selected>Select City</option>
                {city?.length > 0 ? city.map(i => {
                  return (<>
                    <option value={i?.name}>{i?.name}</option>
                  </>)
                })

                  : <p>No data found!</p>}

              </select>
            </div>
          </div>

          <div className="col-md-6 mb-4">
            <div className="border border-dark rounded-2 bg-white d-flex align-items-center px-3">
              <i className="bi bi-pencil"></i>
              <input
                type="email"
                className="border-0 rounded-2 p-2 w-100 px-3"
                placeholder="Enter Your Email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>
          </div>

          <div className="col-md-6 mb-4">
            <div className="border border-dark rounded-2 bg-white d-flex align-items-center px-3">
              <i className="bi bi-pencil"></i>
              <input
                type="tel"
                className="border-0 rounded-2 p-2 w-100 px-3"
                placeholder="Enter Your Phone"
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
              />
            </div>
          </div>

          {/* <div className="col-md-6 mb-4">
            <div className="border border-dark rounded-2 bg-white d-flex align-items-center px-3">
              <i className="bi bi-pencil"></i>
              <input
                type="password"
                className="border-0 rounded-2 p-2 w-100 px-3"
                placeholder="Enter Your Password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </div>
          </div>

          <div className="col-md-6 mb-4">
            <div className="border border-dark rounded-2 bg-white d-flex align-items-center px-3">
              <i className="bi bi-pencil"></i>
              <input
                type="password"
                className="border-0 rounded-2 p-2 w-100 px-3"
                placeholder="Enter Your Confirm Password"
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
              />
            </div>
          </div> */}


        </div>

        <div
          className="p-1 px-3 bg-warning rounded-2 d-flex justify-content-center align-items-center set-shadow"
          style={{ cursor: "pointer", width: "140px" }}
          onClick={() => {
            submit();
          }}
        >
          <h5 className="m-0 p-0">Submit</h5>
        </div>
      </div>
    </div>
  );
};

export default AddSupervisor;
