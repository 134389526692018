import React, { useContext, useEffect, useRef, useState } from 'react'
import Header from '../../Components/Header'
import { useNavigate, useParams } from 'react-router-dom'
import toast from 'react-simple-toasts';
import axios from 'axios';
import BaseUrl from '../../BaseUrl';
import Swal from 'sweetalert2';

const AddQuestion = () => {

    const { cId } = useParams();

    const questType = ['Text', 'Number', 'Radio Button', 'Checkbox']

    const navigate = useNavigate()


    const [data, setData] = useState([])



    const [questionName, setQuestionName] = useState(null)
    const [questionType, setQuestionType] = useState(null)




    const submit = () => {
        if (!questionName) {
            toast('🚨 Must enter your question!')
        }
        else if (!questionType) {
            toast('🚨 Must select question type!')
        }

        else {

            try {

                const param = new FormData()
                param.append('name', questionName)
                param.append('type', questionType)
                param.append('projectId', cId)


                axios.post(`${BaseUrl.baseUrl}/questionAll/create`, param).then(res => {
                    if (res.data.status === 'ok') {
                        // toast('🎉 Created Successfully!')
                        if (data?.length === 0) {
                            toast('🎉 Created Successfully!')
                            navigate(-1)
                        }
                        else {

                            data.map((i, index) => {
                                try {

                                    const param = new FormData()
                                    param.append('productName', i?.productName)
                                    param.append('totalScore', i?.totalScore)
                                    param.append('productImage', i?.productImage)
                                    param.append('questionId', res.data?.data?._id)
                                    param.append('projectId', cId)
                                    i?.subOption.map(j=>{
                                        param.append('subOption', j)
                                    })


                                    axios.post(`${BaseUrl.baseUrl}/subQuestionAll/create`, param).then(resp => {
                                        if (resp.data.status === 'ok') {

                                            if (data?.length === index + 1) {
                                                toast('🎉 Created Successfully!')
                                                navigate(-1)
                                            }
                                        }
                                        else if (resp.data.status === 'fail') {
                                            toast('🚩' + resp.data.message)
                                        }

                                    }).catch(err => {
                                        toast('🚨' + err.message)
                                    })


                                } catch (err) {
                                    toast('🚨 Something went wrong!')
                                }
                            })

                        }

                        // navigate(`/question/${cId}`)
                    }
                    else if (res.data.status === 'fail') {
                        toast('🚩' + res.data.message)
                    }

                }).catch(err => {
                    toast('🚨' + err.message)
                })


            } catch (err) {
                toast('🚨 Something went wrong!')
            }

        }

    }





    const [productImage, setProductImage] = useState(null)
    const [productName, setProductName] = useState(null)
    const [totalScore, setTotalScore] = useState(null)
    const [option, setOption] = useState(null)
    const [subOption, setSubOption] = useState([])
    const fileInputRef = useRef(null);



    const subsubmit = () => {
        if (!productImage) {
            toast('🚨 Must upload image!')
        }
        else if (!productName) {
            toast('🚨 Must add question!')
        }
        else if (!totalScore) {
            toast('🚨 Must add score!')
        }
        else if(questionType==='Checkbox' && subOption.length===0){
            toast('🚨 Must add at least one option!')
        }

        else {

            setData([...data, {
                id: data.length,
                productImage,
                productName,
                totalScore,
                subOption
            }])

            setProductImage('')
            if (fileInputRef.current) {
                fileInputRef.current.value = "";
            }
            setProductName('')
            setTotalScore('')
            setSubOption([]) 

            toast('🎉 Added Successfully!')



        }

    }




    return (
        <div>
            <div>
                <Header name={'Type'} />
            </div>


            <div className='bg-light rounded-2 p-3  mb-4 me-3'>
                <div className='d-flex justify-content-between align-items-start mb-3'>
                    <h3>Add Question Type</h3>
                    <div className='p-1 px-3 bg-warning rounded-2 d-flex justify-content-center align-items-center set-shadow' style={{ cursor: 'pointer' }} onClick={() => { navigate(-1) }}>
                        <h5 className='m-0 p-0'>Back</h5>
                    </div>
                </div>


                <div className='row  w-100'>


                    <div className='col-md-12 mb-4'>
                        <p>Select Question Type</p>
                        <div className='d-flex align-items-center px-3 gap-3'>
                            {questType?.map(i => {
                                return (<>

                                    <div className='d-flex align-items-center px-3 gap-3' onClick={() => { setQuestionType(i) }} style={{ cursor: 'pointer' }} >
                                        <div style={{ width: '20px', height: '20px', borderRadius: 200, border: questionType === i ? '5px solid gold' : '1px solid black' }} />
                                        <p>{i}</p>

                                    </div>

                                </>)
                            })}
                        </div>
                    </div>

                    <div className='col-md-12 mb-4'>
                        <div className='border border-dark rounded-2 bg-white d-flex align-items-center px-3'>
                            <i className='bi bi-pencil'></i>
                            <input type='text' className='border-0 rounded-2 p-2 w-100 px-3' placeholder='Enter Question Description' value={questionName} onChange={(e) => { setQuestionName(e.target.value) }} />
                        </div>
                    </div>




                </div>




                <div className='d-flex justify-content-between align-items-start my-3'>
                    <h3>Add Sub Question</h3>

                </div>

                <div className='row  w-100'>


                    <div className='col-md-6 mb-4'>
                        <div className='border border-dark rounded-2 bg-white d-flex align-items-center px-3'>
                            <i className='bi bi-pencil'></i>
                            <input type='file' ref={fileInputRef} className='border-0 rounded-2 p-2 w-100 px-3' placeholder='Enter Logo' onChange={(e) => { setProductImage(e.target.files[0]) }} />
                        </div>
                    </div>


                    <div className='col-md-6 mb-4'>
                        <div className='border border-dark rounded-2 bg-white d-flex align-items-center px-3'>
                            <i className='bi bi-pencil'></i>
                            <input type='text' className='border-0 rounded-2 p-2 w-100 px-3' placeholder='Enter your question' value={productName} onChange={(e) => { setProductName(e.target.value) }} />
                        </div>
                    </div>

                    <div className='col-md-6 mb-4'>
                        <div className='border border-dark rounded-2 bg-white d-flex align-items-center px-3'>
                            <i className='bi bi-pencil'></i>
                            <input type='number' className='border-0 rounded-2 p-2 w-100 px-3' placeholder='Enter total score' value={totalScore} onChange={(e) => { setTotalScore(e.target.value) }} />
                        </div>
                    </div>




                </div>



                {questionType==='Checkbox'?<div className='my-3 mb-5'>
                    <div className='d-flex justify-content-between align-items-start '>
                        <h3>Add Sub Option</h3>
                    </div>

                    <div className='w-100 d-flex gap-4 align-items-center'>
                        <input type='text' className='border rounded-2 p-2 w-100 px-3' placeholder='Enter your sub option' value={option} onChange={(e) => { setOption(e.target.value) }} />
                        <div className='p-1 px-3 bg-warning rounded-2 d-flex justify-content-center align-items-center set-shadow' style={{ cursor: 'pointer' }} onClick={() => {

                            if (!option) {
                                toast('🚨 Must add sub option!')
                            }
                            else {
                                setSubOption([...subOption, option])
                                setOption('')
                            }

                        }}>
                            <h5 className='m-0 p-0'>Add</h5>
                        </div>
                    </div>


                    <div className='w-100 px-3'>
                        {subOption?.map((i, index) => {
                            return (
                                <div className='d-flex align-items-center justify-content-between gap-2 mt-3 '>
                                    <p>{index+1}- {i}</p>
                                    <button
                                        title="Delete"
                                        className="btn btn-outline-danger btn-sm"
                                        onClick={() => {
                                            setSubOption(subOption.filter((fil) => i !== fil))
                                            toast('��� Deleted Successfully!')
                                        }}
                                    >
                                        <i className="bi bi-trash-fill"></i>
                                    </button>
                                </div>

                            )
                        })}

                    </div>
                </div>:null}




                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <div className='p-1 px-3 mb-5 bg-warning rounded-2 d-flex justify-content-center align-items-center set-shadow' style={{ cursor: 'pointer', width: '140px' }} onClick={() => { subsubmit() }}>
                        <h5 className='m-0 p-0'>Add</h5>
                    </div>
                </div>





                <div className="border border-dark rounded-2 p-3 w-100 mb-4">
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Image</th>
                                    <th scope="col">Question</th>
                                    <th scope="col">Total Score</th>
                                </tr>
                            </thead>
                            <tbody>


                                {data?.length > 0 ? (
                                    data?.map((i, index) => {
                                        return (
                                            <>
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>
                                                        <img src={URL.createObjectURL(i?.productImage)} style={{ width: '45px', height: '45px', objectFit: 'cover', borderRadius: 200 }} />
                                                    </td>
                                                    <td>{i?.productName}</td>
                                                    <td>{i?.totalScore}</td>
                                                    <td>
                                                        <div className="d-flex align-items-center gap-3">

                                                            <button
                                                                title="Delete"
                                                                className="btn btn-outline-danger btn-sm"
                                                                onClick={() => {
                                                                    setData(data.filter((fil) => i?.id !== fil?.id))
                                                                    toast('��� Deleted Successfully!')
                                                                }}
                                                            >
                                                                <i className="bi bi-trash-fill"></i>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </>
                                        );
                                    })
                                ) : (
                                    <p>No data found!</p>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>








                <div className='p-1 px-3 mb-5 bg-warning rounded-2 d-flex justify-content-center align-items-center set-shadow' style={{ cursor: 'pointer', width: '140px' }} onClick={() => { submit() }}>
                    <h5 className='m-0 p-0'>Submit</h5>
                </div>


            </div>








        </div>
    )
}

export default AddQuestion