import React, { useEffect, useState } from 'react'
import Header from '../../Components/Header'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import BaseUrl from '../../BaseUrl'
import toast from 'react-simple-toasts'
import Swal from 'sweetalert2'

const Store = () => {


    useEffect(() => {
        try {

            axios.get(`${BaseUrl.baseUrl}/task/createQuest`).then(res => {
                if (res.data.status === 'ok') {
                    // setStore(res.data.data)
                }

            }).catch(err => {
                // toast('🚨' + err.message)
                console.log(err);
            })


        } catch (err) {
            console.log(err);
            // toast('🚨 Something went wrong!')
        }

    }, [])

    const { cId } = useParams()


    const navigate = useNavigate()

    const [data, setData] = useState([])


    useEffect(() => {
        try {

            axios.get(`${BaseUrl.baseUrl}/store/getProject/${cId}`).then(res => {
                if (res.data.status === 'ok') {
                    setData(res.data.data)
                }
                else if (res.data.status === 'fail') {
                    toast('🚩' + res.data.message)
                }

            }).catch(err => {
                toast('🚨' + err.message)
            })


        } catch (err) {
            toast('🚨 Something went wrong!')
        }

    }, [])





    const del = (id) => {

        Swal.fire({
            title: "Are you sure?",
            text: "Do you want to delete this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {





            if (result.isConfirmed) {
                try {

                    axios.delete(`${BaseUrl.baseUrl}/store/delete/${id}`).then(res => {
                        if (res.data.status === 'ok') {
                            toast('🎉 Deleted Successfully!')

                            axios.get(`${BaseUrl.baseUrl}/store/getProj/${cId}`).then(res => {
                                if (res.data.status === 'ok') {
                                    setData(res.data.data)
                                }
                                else if (res.data.status === 'fail') {
                                    toast('🚩' + res.data.message)
                                }

                            }).catch(err => {
                                toast('🚨' + err.message)
                            })

                        }
                        else if (res.data.status === 'fail') {
                            toast('🚩' + res.data.message)
                        }

                    }).catch(err => {
                        toast('🚨' + err.message)
                    })


                } catch (err) {
                    toast('🚨 Something went wrong!')
                }
            }


        })
    }

    const [selStore, setSelStore] = useState([])

    const getSelStore = (id) => {
        if (selStore.includes(id)) {
            // Remove the id from the array
            setSelStore(selStore.filter(i => i !== id));
        } else {
            // Add the id to the array
            setSelStore([...selStore, id]);
        }
    }


    const [store, setStore] = useState([])
    const [city, setCity] = useState(null)


    useEffect(() => {
        try {

            axios.get(`${BaseUrl.baseUrl}/store/getProject/${cId}`).then(res => {
                if (res.data.status === 'ok') {
                    setStore(res.data.data)
                }
                else if (res.data.status === 'fail') {
                    toast('🚩' + res.data.message)
                }

            }).catch(err => {
                toast('🚨' + err.message)
            })


        } catch (err) {
            toast('🚨 Something went wrong!')
        }

    }, [])



    const [manager, setManager] = useState([])
    const [selManager, setSelManager] = useState(null)


    useEffect(() => {
        try {

            axios.get(`${BaseUrl.baseUrl}/manager/getProj/${cId}`).then(res => {
                if (res.data.status === 'ok') {
                    setManager(res.data.data)
                }
                else if (res.data.status === 'fail') {
                    toast('🚩' + res.data.message)
                }

            }).catch(err => {
                toast('🚨' + err.message)
            })


        } catch (err) {
            toast('🚨 Something went wrong!')
        }

    }, [])






    const [supervisor, setSupervisor] = useState([])
    const [selSupervisor, setSelSupervisor] = useState(null)




    const getsupervisor = () => {
        try {

           
            axios.get(`${BaseUrl.baseUrl}/supervisor/getProj/${cId}/${selManager}`).then(res => {
                if (res.data.status === 'ok') {
                    setSupervisor(res.data.data)
                }
                else if (res.data.status === 'fail') {
                    toast('🚩' + res.data.message)
                }

            }).catch(err => {
                console.log('🚨' + err.message)
            })
        

        } catch (err) {
            toast('🚨 Something went wrong!')
        }
    }




    const [saleman, setSaleman] = useState([])
    const [selSaleman, setSelSaleman] = useState(null)




    const getSaleman = () => {
        try {
            axios.get(`${BaseUrl.baseUrl}/saleman/getProj/${cId}/${selManager}/${selSupervisor}`).then(res => {
                if (res.data.status === 'ok') {
                    setSaleman(res.data.data)
                } else if (res.data.status === 'fail') {
                    toast('🚩' + res.data.message);
                }
            }).catch(err => {
                console.log('🚨' + err.message)
            });
        } catch (err) {
            toast('🚨 Something went wrong!');
        }
    };





    const filterdata = () => {
        try {
            axios.get(`${BaseUrl.baseUrl}/store/filter/${selManager}/${selSupervisor}/${selSaleman}/${city}`).then(res => {
                if (res.data.status === 'ok') {
                    setData(res.data.data)
                } else if (res.data.status === 'fail') {
                    toast('🚩' + res.data.message);
                }
            }).catch(err => {
                console.log('🚨' + err.message)
            });
        } catch (err) {
            toast('🚨 Something went wrong!');
        }
    };




    return (
        <div>
            <div>
                <Header name={'Store'} />
            </div>

            <div className="d-flex justify-content-between align-items-center p-3">
                <div></div>
                <div className="d-flex align-items-center gap-3 flex-wrap">

                    <div className='border border-dark rounded-2 bg-white d-flex align-items-center px-3'>
                        <select class="form-select" aria-label="Default select example" onChange={(e) => { setSelManager(e.target.value) }}>
                            <option selected>Select Manager</option>
                            {manager?.length > 0 ?
                                manager.map(i => (
                                    <option key={i?._id} value={i?._id}>{i?.firstName + " " + i?.lastName}</option>
                                )) :
                                <p>No data found!</p>}
                        </select>
                    </div>




                    <div className='border border-dark rounded-2 bg-white d-flex align-items-center px-3' onClick={getsupervisor}>
                        <select class="form-select" aria-label="Default select example" onChange={(e) => { setSelSupervisor(e.target.value) }}>
                            <option selected>Select Supervisor</option>
                            {supervisor?.length > 0 ?
                                supervisor.map(i => (
                                    <option key={i?._id} value={i?._id}>{i?.firstName + " " + i?.lastName}</option>
                                )) :
                                <p>No data found!</p>}
                        </select>
                    </div>



                    <div className='border border-dark rounded-2 bg-white d-flex align-items-center px-3' onClick={getSaleman}>
                        <select class="form-select" aria-label="Default select example" onChange={(e) => { setSelSaleman(e.target.value) }}>
                            <option selected>Select Saleman</option>
                            {saleman?.length > 0 ?
                                saleman.map(i => (
                                    <option key={i?._id} value={i?._id}>{i?.firstName + " " + i?.lastName}</option>
                                )) :
                                <p>No data found!</p>}
                        </select>
                    </div>




                    <div className='border border-dark rounded-2 bg-white d-flex align-items-center px-3'>
                        <select class="form-select" aria-label="Default select example" onChange={(e) => { setCity(e.target.value) }}>
                            <option selected>Select City</option>
                            {store?.length > 0 ?
                                [...new Set(store.map(i => i.city))].map(city => (
                                    <option key={city} value={city}>{city}</option>
                                )) :
                                <p>No data found!</p>}
                        </select>
                    </div>



                    <div className='border border-dark rounded-2 bg-success d-flex align-items-center'>
                        <button className='border-0 rounded-2 p-2 w-100 px-3 bg-warning' 
                        onClick={filterdata}
                        >
                            Submit
                        </button>
                    </div>

                </div>
            </div>


            <div className='bg-light rounded-2 p-3  mb-4 me-3'>
                <div className='d-flex justify-content-between align-items-start mb-3'>
                    <h3>Store</h3>
                    <div className='d-flex gap-3'>
                        {selStore.length > 0 ? <div className='p-1 px-3 bg-warning rounded-2 d-flex justify-content-center align-items-center set-shadow' style={{ cursor: 'pointer' }} onClick={() => { navigate(`/addtransfer/${cId}/${JSON.stringify(selStore)}`) }}>
                            <h5 className='m-0 p-0'>Assign</h5>
                        </div> : null}

                        <div className='p-1 px-3 bg-warning rounded-2 d-flex justify-content-center align-items-center set-shadow' style={{ cursor: 'pointer' }} onClick={() => { navigate(`/addstore/${cId}`) }}>
                            <h5 className='m-0 p-0'>Add</h5>
                        </div>

                        <div className='p-1 px-3 bg-warning rounded-2 d-flex justify-content-center align-items-center set-shadow' style={{ cursor: 'pointer' }} onClick={() => { navigate(`/viewproject/${cId}`) }}>
                            <h5 className='m-0 p-0'>Back</h5>
                        </div>
                    </div>
                </div>


                <div className='border border-dark rounded-2 p-3 w-100'>
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">{selStore.length}</th>
                                    <th scope="col">Image</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">City</th>
                                    <th scope="col">Manager Name</th>
                                    <th scope="col">Supervisor</th>
                                    <th scope="col">Saleman</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.length > 0 ? data?.map(i => {
                                    return (<>

                                        <tr>
                                            <td>
                                                <div style={{ width: '23px', height: '23px', borderRadius: '5px', border: '1px solid black', cursor: 'pointer' }} className='d-flex justify-content-center align-items-center pt-1' onClick={() => { getSelStore(i?._id) }}>
                                                    {selStore?.includes(i?._id) ? <i class="bi bi-check-square-fill text-warning fs-5"></i> : null}
                                                </div>
                                            </td>
                                            <td>
                                                <img src={i?.image} style={{ width: '45px', height: '45px', objectFit: 'cover', borderRadius: 200 }} />
                                            </td>
                                            <td>{i?.name}</td>
                                            <td>{i?.city}</td>
                                            <td>{i?.managerId?.firstName + ' ' + i?.managerId?.lastName}</td>
                                            <td>{i?.supervisorId.length}</td>
                                            <td>{i?.salemanId.length}</td>
                                            <td>
                                                <div className='d-flex align-items-center gap-3'>
                                                    <button className='btn btn-outline-success btn-sm' onClick={() => { navigate(`/transfer/${cId}/${i?._id}`) }} title='Assign to'>
                                                        <i className="bi bi-cursor-fill"></i>
                                                    </button>
                                                    <button className='btn btn-outline-info btn-sm' onClick={() => { navigate(`/editstore/${i?._id}`) }} title='Edit'>
                                                        <i className='bi bi-pencil'></i>
                                                    </button>
                                                    <button className='btn btn-outline-danger btn-sm' onClick={() => { del(i?._id) }} title='Delete'>
                                                        <i className='bi bi-trash-fill'></i>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>

                                    </>)
                                }) :
                                    <p>No data found!</p>
                                }

                            </tbody>
                        </table>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default Store