import React, { useEffect, useState } from 'react'
import Header from '../../Components/Header'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import BaseUrl from '../../BaseUrl'
import toast from 'react-simple-toasts'
import Swal from 'sweetalert2'
import Chart from 'react-apexcharts'


const ViewProject = () => {

  const { id } = useParams()




  const seriest = [90]
  const optionst = {
    chart: {
      height: 200,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: '70%',
        }
      },
    },
    labels: ['Store Scoring'],
    colors: ['#FA03E0',],
  }




  const options = {
    chart: {
      height: 350,
      type: 'area'
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    xaxis: {
      type: 'datetime',
      categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy HH:mm'
      },
    },
  }




  const series = [{
    name: 'Completed',
    color: '#F6A835',
    data: [31, 40, 28, 51, 42, 109, 100]
  }, {
    name: 'InComplete',
    color: '#E54C0A',
    data: [11, 32, 45, 32, 34, 52, 41]
  }]






  const [proj, setProj] = useState(null)


  useEffect(() => {
      try {

          axios.get(`${BaseUrl.baseUrl}/project/get/${id}`).then(res => {
              console.log(res.data);
              if (res.data.status === 'ok') {
                setProj(res.data.data)
              }
              else if (res.data.status === 'fail') {
                  toast('🚩' + res.data.message)
              }

          }).catch(err => {
              toast('🚨' + err.message)
          })


      } catch (err) {
          toast('🚨 Something went wrong!')
      }

  }, [])



  const [manager, setManager] = useState([])


  useEffect(() => {
      try {

          axios.get(`${BaseUrl.baseUrl}/manager/getProj/${id}`).then(res => {
              console.log(res.data);
              if (res.data.status === 'ok') {
                setManager(res.data.data)
              }
              else if (res.data.status === 'fail') {
                  toast('🚩' + res.data.message)
              }

          }).catch(err => {
              toast('🚨' + err.message)
          })


      } catch (err) {
          toast('🚨 Something went wrong!')
      }

  }, [])




  const [scData,setScData]=useState([])

  const updateScore = (mydata) => {
    try {

      mydata?.map(async(i)=>{


        const task=await axios.get(`${BaseUrl.baseUrl}/task/getStore/${id}/${i?._id}`)
        console.log(task?.data,'abc');

        const param = new FormData()

        param.append('givenScore',task?.data?.data?.reduce(
          (accumulator, currentValue) =>
            parseInt(accumulator) + parseInt(currentValue?.givenScore?currentValue?.givenScore:0),
          0
        ))
        param.append('totalScore',task?.data?.data?.reduce(
          (accumulator, currentValue) =>
            parseInt(accumulator) + parseInt(currentValue?.totalScore?currentValue?.totalScore:0),
          0
        ))




        axios
          .put(`${BaseUrl.baseUrl}/store/update/${i?._id}`, param)
          .then((res) => {
            if (res.data.status === "ok") {


              axios.get(`${BaseUrl.baseUrl}/store/getProject/${id}`).then(res => {
                if (res.data.status === 'ok') {
                  setScData(res.data.data)
                }
                else if (res.data.status === 'fail') {
                    toast('🚩' + res.data.message)
                }
    
            }).catch(err => {
                toast('🚨' + err.message)
            })



            } else if (res.data.status === "fail") {
              toast("🚩" + res.data.message);
            }
          })
          .catch((err) => {
            toast("🚨" + err.message);
          });

          
      })
      
    } catch (err) {
      toast("🚨 Something went wrong!");
    }
  };





  useEffect(() => {
    try {

        axios.get(`${BaseUrl.baseUrl}/store/getProject/${id}`).then(res => {
            if (res.data.status === 'ok') {

              updateScore(res.data.data)
              setScData(res.data.data)
            }
            else if (res.data.status === 'fail') {
                toast('🚩' + res.data.message)
            }

        }).catch(err => {
            toast('🚨' + err.message)
        })


    } catch (err) {
        toast('🚨 Something went wrong!')
    }

}, [])










  const [storeScore, setStoreScore] = useState([])


  useEffect(() => {
      try {

          axios.get(`${BaseUrl.baseUrl}/store/getScore/${id}`).then(res => {
              console.log(res.data,'score');
              if (res.data.status === 'ok') {
                setStoreScore(res.data.data)
              }
              else if (res.data.status === 'fail') {
                  toast('🚩' + res.data.message)
              }

          }).catch(err => {
              toast('🚨' + err.message)
          })


      } catch (err) {
          toast('🚨 Something went wrong!')
      }

  }, [])




  const [supervisor, setSupervisor] = useState([])


  useEffect(() => {
      try {

          axios.get(`${BaseUrl.baseUrl}/supervisor/getProject/${id}`).then(res => {
              console.log(res.data);
              if (res.data.status === 'ok') {
                setSupervisor(res.data.data)
              }
              else if (res.data.status === 'fail') {
                  toast('🚩' + res.data.message)
              }

          }).catch(err => {
              toast('🚨' + err.message)
          })


      } catch (err) {
          toast('🚨 Something went wrong!')
      }

  }, [])



  const [saleman, setSaleman] = useState([])


  useEffect(() => {
      try {

          axios.get(`${BaseUrl.baseUrl}/saleman/getProject/${id}`).then(res => {
              console.log(res.data);
              if (res.data.status === 'ok') {
                setSaleman(res.data.data)
              }
              else if (res.data.status === 'fail') {
                  toast('🚩' + res.data.message)
              }

          }).catch(err => {
              toast('🚨' + err.message)
          })


      } catch (err) {
          toast('🚨 Something went wrong!')
      }

  }, [])



  const [store, setStore] = useState([])


  useEffect(() => {
      try {

          axios.get(`${BaseUrl.baseUrl}/store/getProject/${id}`).then(res => {
              console.log(res.data);
              if (res.data.status === 'ok') {
                setStore(res.data.data)
              }
              else if (res.data.status === 'fail') {
                  toast('🚩' + res.data.message)
              }

          }).catch(err => {
              toast('🚨' + err.message)
          })


      } catch (err) {
          toast('🚨 Something went wrong!')
      }

  }, [])



  const [question, setQuestion] = useState([])


  useEffect(() => {
      try {

          axios.get(`${BaseUrl.baseUrl}/subQuestionAll/getProject/${id}`).then(res => {
              console.log(res.data);
              if (res.data.status === 'ok') {
                setQuestion(res.data.data)
              }
              else if (res.data.status === 'fail') {
                  toast('🚩' + res.data.message)
              }

          }).catch(err => {
              toast('🚨' + err.message)
          })


      } catch (err) {
          toast('🚨 Something went wrong!')
      }

  }, [])




  const navigate = useNavigate()

  const [data, setData] = useState([1,2,3,4])


  useEffect(() => {
    try {

      axios.get(`${BaseUrl.baseUrl}/company/get`).then(res => {
        if (res.data.status === 'ok') {
          setData(res.data.data)
        }
        else if (res.data.status === 'fail') {
          toast('🚩' + res.data.message)
        }

      }).catch(err => {
        toast('🚨' + err.message)
      })


    } catch (err) {
      toast('🚨 Something went wrong!')
    }

  }, [])





  const del = (id) => {

    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {





      if (result.isConfirmed) {
        try {

          axios.delete(`${BaseUrl.baseUrl}/company/delete/${id}`).then(res => {
            if (res.data.status === 'ok') {
              toast('🎉 Deleted Successfully!')

              axios.get(`${BaseUrl.baseUrl}/company/get`).then(res => {
                if (res.data.status === 'ok') {
                  setData(res.data.data)
                }
                else if (res.data.status === 'fail') {
                  toast('🚩' + res.data.message)
                }

              }).catch(err => {
                toast('🚨' + err.message)
              })

            }
            else if (res.data.status === 'fail') {
              toast('🚩' + res.data.message)
            }

          }).catch(err => {
            toast('🚨' + err.message)
          })


        } catch (err) {
          toast('🚨 Something went wrong!')
        }
      }


    })
  }


  useEffect(() => {
    try {

      axios.get(`${BaseUrl.baseUrl}/task/createQuest`).then(res => {
        if (res.data.status === 'ok') {
          // setStore(res.data.data)
        }

      }).catch(err => {
        // toast('🚨' + err.message)
        console.log(err);
      })


    } catch (err) {
      console.log(err);
      // toast('🚨 Something went wrong!')
    }

  }, [])




  return (
    <div>
      <div>
        <Header name={'Project'} />
      </div>


      <div className='bg-light rounded-2 p-3  mb-4 me-3'>
        <div className='d-flex justify-content-between align-items-start mb-3'>
          <h3>{proj?.name}</h3>
          <div className='d-flex align-items-center gap-4'>
            <div className='p-1 px-3 bg-warning rounded-2 d-flex justify-content-center align-items-center set-shadow' style={{ cursor: 'pointer' }} onClick={() => { navigate(`/city`) }}>
              <h5 className='m-0 p-0'>City</h5>
            </div>
            <div className='p-1 px-3 bg-warning rounded-2 d-flex justify-content-center align-items-center set-shadow' style={{ cursor: 'pointer' }} onClick={() => { navigate(`/manager/${id}`) }}>
              <h5 className='m-0 p-0'>Manager({manager?.length})</h5>
            </div>
            <div className='p-1 px-3 bg-warning rounded-2 d-flex justify-content-center align-items-center set-shadow' style={{ cursor: 'pointer' }} onClick={() => { navigate(`/supervisor/${id}`) }}>
              <h5 className='m-0 p-0'>Supervisor({supervisor?.length})</h5>
            </div>
            <div className='p-1 px-3 bg-warning rounded-2 d-flex justify-content-center align-items-center set-shadow' style={{ cursor: 'pointer' }} onClick={() => { navigate(`/saleman/${id}`) }}>
              <h5 className='m-0 p-0'>Salesman({saleman?.length})</h5>
            </div>
            <div className='p-1 px-3 bg-warning rounded-2 d-flex justify-content-center align-items-center set-shadow' style={{ cursor: 'pointer' }} onClick={() => { navigate(`/store/${id}`) }}>
              <h5 className='m-0 p-0'>Store({store?.length})</h5>
            </div>
            <div className='p-1 px-3 bg-warning rounded-2 d-flex justify-content-center align-items-center set-shadow' style={{ cursor: 'pointer' }} onClick={() => { navigate(`/question/${id}`) }}>
              <h5 className='m-0 p-0'>Question({question?.length})</h5>
            </div>
          </div>
        </div>




        <div className='my-3 mb-5'>
          <h4 className='m-0 p-0 mb-3'>Store Scoring</h4>
          <div className='row w-100'>

            {scData.map(i=>{
              return(<>
              <div className='col-md-3'>
              <div className='bg-warning p-3 rounded-2 set-shadow d-flex justify-content-center align-items-center text-center mb-4' style={{ cursor: 'pointer' }}>
              <div>
                <h6>{i?.name}</h6>
                <h2>{`${i?.givenScore?i?.givenScore:0}/${i?.totalScore?i?.totalScore:0}`}</h2>
                <div className='d-flex justify-content-center align-items-center gap-3'>
                  <div>
                    <div className='bg-light  rounded-2' style={{ width: '150px', height: '10px' }}>
                      <div className='bg-dark rounded-2' style={{ height: '100%', width: `${parseInt(i?.totalScore?((i?.givenScore?i?.givenScore:0)/(i?.totalScore?i?.totalScore:0)*100):0)}%` }} />
                    </div>
                  </div>
                  <div>
                    <p className='m-0 p-0'>{parseInt(i?.totalScore?((i?.givenScore?i?.givenScore:0)/(i?.totalScore?i?.totalScore:0)*100):0)}%</p>
                  </div>
                </div>
              </div>
            </div>
            </div>
              </>)
            })}
            {/* <Chart options={optionsm} series={seriesm} type="radialBar" height={250} width={200} /> */}
          </div>

        </div>






        <div className='w-100 mt-3'>
          {/* <Chart options={options} series={series} type="area" height={380} width={'100%'} /> */}
        </div>

      </div>

    </div>
  )
}

export default ViewProject