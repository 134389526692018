import logo from "./logo.svg";
import "./App.css";
import Chart from "chart.js/auto";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Wrapper from "./Pages/Wrapper";
import Home from "./Pages/Home";
import Project from "./Pages/Project/Project";
import AddProject from "./Pages/Project/AddProject";
import Login from "./Pages/Login";
import Product from "./Pages/Product/Product";
import AddProduct from "./Pages/Product/AddProduct";
import OpenMap from "./Components/OpenMap";
import MapContext from "./Components/MapContext";
import { useEffect, useState } from "react";
import { toastConfig } from "react-simple-toasts";
import "react-simple-toasts/dist/theme/dark.css"; // choose your theme
import EditProject from "./Pages/Project/EditProject";
import EditProduct from "./Pages/Product/EditProduct";
import Supervisor from "./Pages/Supervisor/Supervisor";
import Employe from "./Pages/Employe/Employe";
import AddEmploye from "./Pages/Employe/AddEmploye";
import EditEmploye from "./Pages/Employe/EditEmploye";
import Store from "./Pages/Store/Store";
import AddStore from "./Pages/Store/AddStore";
import EditStore from "./Pages/Store/EditStore";
import Tasks from "./Pages/Tasks/Tasks";
import AddTasks from "./Pages/Tasks/AddTasks";
import EditTasks from "./Pages/Tasks/EditTasks";
import Reports from "./Pages/Reports/Reports";
import Performances from "./Pages/Performances/Performances";
import AddPerformances from "./Pages/Performances/AddPreformances";
import EditPerformances from "./Pages/Performances/EditPerformances";
import Question from "./Pages/Question/Question";
import AddQuestion from "./Pages/Question/AddQuestion";
import EditQuestion from "./Pages/Question/EditQuestion";
import SubQuestion from "./Pages/SubQuestion/SubQuestion";
import AddSubQuestion from "./Pages/SubQuestion/AddSubQuestion";
import EditSubQuestion from "./Pages/SubQuestion/EditSubQuestion";
import EditCity from "./Pages/City/EditCity";
import AddCity from "./Pages/City/AddCity";
import City from "./Pages/City/City";
import ViewReport from "./Pages/Reports/ViewReport";
import EmailTemp from "./Pages/EmailTemp";
import ViewProject from "./Pages/Project/ViewProject";
import AddSupervisor from "./Pages/Supervisor/AddSupervisor";
import EditSupervisor from "./Pages/Supervisor/EditSupervisor"; 
import Manager from "./Pages/Manager/Manager";
import AddManager from "./Pages/Manager/AddManager";
import EditManager from "./Pages/Manager/EditManager";
import Saleman from "./Pages/Saleman/Saleman";
import AddSaleman from "./Pages/Saleman/AddSaleman";
import EditSaleman from "./Pages/Saleman/EditSaleman";
import BaseUrl from "./BaseUrl";
import axios from "axios";
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import 'react-responsive-modal/styles.css';
import 'react-slideshow-image/dist/styles.css'


function App() {
  toastConfig({ theme: "dark" });

  const [mapValue, setMapValue] = useState(null);


  useEffect(() => {
    try {

      axios.get(`${BaseUrl.baseUrl}/task/createQuest`).then(res => {
        if (res.data.status === 'ok') {
          // setStore(res.data.data)
        }

      }).catch(err => {
        // toast('🚨' + err.message)
        console.log(err);
      })


    } catch (err) {
      console.log(err);
      // toast('🚨 Something went wrong!')
    }

  }, [])



  return (
    <>
      <MapContext.Provider
        value={{ mapValue: mapValue, setMapValue: setMapValue }}
      >
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/email" element={<EmailTemp />} />
            <Route path="/openMap" element={<OpenMap />} />
            <Route element={<Wrapper />}>
              <Route path="/home" element={<Home />} />
              <Route path="/project" element={<Project />} />
              <Route path="/addproject" element={<AddProject />} />
              <Route path="/editproject/:id" element={<EditProject />} />
              <Route path="/viewproject/:id" element={<ViewProject />} />
              <Route path="/city" element={<City />} />
              <Route path="/addcity" element={<AddCity />} />
              <Route path="/editcity/:id" element={<EditCity />} />
              <Route path="/store/:cId" element={<Store />} />
              <Route path="/addstore/:cId" element={<AddStore />} />
              <Route path="/editstore/:id" element={<EditStore/>} />
              <Route path="/product/:cId" element={<Product />} />
              <Route path="/addproduct/:cId" element={<AddProduct />} />
              <Route path="/editproduct/:id" element={<EditProduct />} />
              <Route path="/saleman/:cId" element={<Saleman />} /> 
              <Route path="/addsaleman/:cId" element={<AddSaleman />} />
              <Route path="/editsaleman/:id" element={<EditSaleman />} />
              <Route path="/manager/:cId" element={<Manager />} /> 
              <Route path="/addmanager/:cId" element={<AddManager />} />
              <Route path="/editmanager/:id" element={<EditManager />} />
              <Route path="/supervisor/:cId" element={<Supervisor />} />
              <Route path="/addsupervisor/:cId" element={<AddSupervisor />} />
              <Route path="/editsupervisor/:id" element={<EditSupervisor />} />
              <Route path="/team" element={<Employe />} />
              <Route path="/addteam" element={<AddEmploye />} />
              <Route path="/editteam/:id" element={<EditEmploye />} />
              <Route path="/transfer/:cId/:sId" element={<Tasks />} />
              <Route path="/addtransfer/:cId/:sId" element={<AddTasks />} />
              <Route path="/edittransfer/:id" element={<EditTasks />} />
              <Route path="/question/:cId" element={<Question />} />
              <Route path="/addquestion/:cId" element={<AddQuestion />} />
              <Route path="/editquestion/:id" element={<EditQuestion />} />
              <Route path="/subQuestion/:cId/:questionId" element={<SubQuestion />} />
              <Route path="/addsubQuestion/:cId/:questionId" element={<AddSubQuestion />} />
              <Route path="/editsubQuestion/:id/:questionId" element={<EditSubQuestion />} />
              <Route path="/reports" element={<Reports />} />
              <Route path="/viewreport/:id" element={<ViewReport/>} />
              <Route path="/performances" element={<Performances />} />
              <Route path="/addperformances" element={<AddPerformances/>} />
              <Route path="/editperformances/:id" element={<EditPerformances />} />
             
            </Route>
          </Routes>
        </BrowserRouter>
      </MapContext.Provider>
    </>
  );
}

export default App;
