import React, { useContext, useEffect, useState } from "react";
import Header from "../../Components/Header";
import { useNavigate, useParams } from "react-router-dom";
import MapContext from "../../Components/MapContext";
import toast from "react-simple-toasts";
import axios from "axios";
import BaseUrl from "../../BaseUrl";
import Swal from "sweetalert2";

const EditSubQuestion = () => {
  const navigate = useNavigate();
  const { id, questionId } = useParams();








  const [questionType, setQuestionType] = useState(null)
  const [name, setName] = useState(null)
  const [totalScore, setTotalScore] = useState(null)
  const [image, setImage] = useState(null)
  const [option, setOption] = useState(null)
  const [subOption, setSubOption] = useState([])



  useEffect(() => {
    axios.get(`${BaseUrl.baseUrl}/questionAll/get/${questionId}`).then(res => {
      

      if (res.data.status === 'ok') {
        setQuestionType(res.data.data.type)
      }
    }).catch(err => {
      toast('���' + err.message)
    })


  }, [])




  const [data, setData] = useState({});



  useEffect(() => {
    try {
      axios
        .get(`${BaseUrl.baseUrl}/subQuestionAll/get/${id}`)
        .then((res) => {
          if (res.data.status === "ok") {
            console.log(res.data.data);
            setData(res.data.data);
            setName(res.data.data.productName);
            setTotalScore(res.data.data.totalScore);
            setSubOption(res.data.data.subOption);
          } else if (res.data.status === "fail") {
            toast("🚩" + res.data.message);
          }
        })
        .catch((err) => {
          toast("🚨" + err.message);
        });
    } catch (err) {
      toast("🚨 Something went wrong!");
    }
  }, []);




  const submit = () => {
    try {
      const param = new FormData();

      if (image) {
        param.append('productImage', image)
      }
      if (name !== data?.name) {
        param.append('productName', name)
      }
      if (totalScore !== data?.totalScore) {
        param.append('totalScore', totalScore)
      }

      subOption.map(j=>{
        param.append('subOption', j)
    })


      axios
        .put(`${BaseUrl.baseUrl}/subQuestionAll/update/${id}`, param)
        .then((res) => {
          if (res.data.status === "ok") {
            toast("🎉 Updated Successfully!");
            navigate(-1);
          } else if (res.data.status === "fail") {
            toast("🚩" + res.data.message);
          }
        })
        .catch((err) => {
          toast("🚨" + err.message);
        });
    } catch (err) {
      toast("🚨 Something went wrong!");
    }
  };

  return (
    <div>
      <div>
        <Header name={"Question"} />
      </div>

      <div className="bg-light rounded-2 p-3  mb-4 me-3">
        <div className="d-flex justify-content-between align-items-start mb-3">
          <h3>Edit Question</h3>
          <div className='p-1 px-3 bg-warning rounded-2 d-flex justify-content-center align-items-center set-shadow' style={{ cursor: 'pointer' }} onClick={() => { navigate(-1) }}>
            <h5 className='m-0 p-0'>Back</h5>
          </div>
        </div>

        <div className='row  w-100'>

          <div className='col-md-12 mb-4'>
            <div className='d-flex align-items-center px-3 position-relative'>
              <img src={image ? URL?.createObjectURL(image) : data?.productImage} className='border' style={{ width: '120px', height: '120px', objectFit: 'cover', borderRadius: 200 }} />
              {image ? <div className={`d-flex justify-content-center align-items-center bg-${image ? 'danger' : 'warning'} position-absolute`} style={{ width: '35px', height: '35px', borderRadius: 200, top: '10px', left: '5px' }} onClick={() => { setImage(null) }}>
                <i className={`bi bi-x fs-4 text-white`}></i>
              </div> :
                <div className={`d-flex justify-content-center align-items-center bg-${image ? 'danger' : 'warning'} position-absolute`} style={{ width: '35px', height: '35px', borderRadius: 200, top: '10px', left: '5px' }}>
                  <i className={`bi bi-pencil fs-6`}></i>
                </div>}
              {image ? null : <input type='file' className='border rounded-2 p-2 px-3 position-absolute' placeholder='Enter Logo' style={{ width: '120px', height: '120px', borderRadius: 200, opacity: 0 }} onChange={(e) => { setImage(e.target.files[0]) }} />}
            </div>
          </div>


          <div className='col-md-6 mb-4'>
            <div className='border border-dark rounded-2 bg-white d-flex align-items-center px-3'>
              <i className='bi bi-pencil'></i>
              <input type='text' className='border-0 rounded-2 p-2 w-100 px-3' placeholder='Enter Company Name' defaultValue={name} onChange={(e) => { setName(e.target.value) }} />
            </div>
          </div>


          <div className='col-md-6 mb-4'>
            <div className='border border-dark rounded-2 bg-white d-flex align-items-center px-3'>
              <i className='bi bi-pencil'></i>
              <input type='text' className='border-0 rounded-2 p-2 w-100 px-3' placeholder='Enter Total score' defaultValue={totalScore} onChange={(e) => { setTotalScore(e.target.value) }} />
            </div>
          </div>


        </div>


        {questionType==='Checkbox'?<div className='my-3 mb-5'>
                    <div className='d-flex justify-content-between align-items-start '>
                        <h3>Add Sub Option</h3>
                    </div>

                    <div className='w-100 d-flex gap-4 align-items-center'>
                        <input type='text' className='border rounded-2 p-2 w-100 px-3' placeholder='Enter your sub option' value={option} onChange={(e) => { setOption(e.target.value) }} />
                        <div className='p-1 px-3 bg-warning rounded-2 d-flex justify-content-center align-items-center set-shadow' style={{ cursor: 'pointer' }} onClick={() => {

                            if (!option) {
                                toast('🚨 Must add sub option!')
                            }
                            else {
                                setSubOption([...subOption, option])
                                setOption('')
                            }

                        }}>
                            <h5 className='m-0 p-0'>Add</h5>
                        </div>
                    </div>


                    <div className='w-100 px-3'>
                        {subOption?.map((i, index) => {
                            return (
                                <div className='d-flex align-items-center justify-content-between gap-2 mt-3 '>
                                    <p>{index+1}- {i}</p>
                                    <button
                                        title="Delete"
                                        className="btn btn-outline-danger btn-sm"
                                        onClick={() => {
                                            setSubOption(subOption.filter((fil) => i !== fil))
                                            toast('��� Deleted Successfully!')
                                        }}
                                    >
                                        <i className="bi bi-trash-fill"></i>
                                    </button>
                                </div>

                            )
                        })}

                    </div>
                </div>:null}

        <div
          className="p-1 px-3 bg-warning rounded-2 d-flex justify-content-center align-items-center set-shadow"
          style={{ cursor: "pointer", width: "140px" }}
          onClick={() => {
            submit();
          }}
        >
          <h5 className="m-0 p-0">Submit</h5>
        </div>
      </div>
    </div>
  );
};

export default EditSubQuestion;
