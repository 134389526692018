import React, { useContext, useEffect, useState } from 'react'
import Header from '../../Components/Header'
import { useNavigate } from 'react-router-dom'
import MapContext from '../../Components/MapContext';
import toast from 'react-simple-toasts';
import axios from 'axios';
import BaseUrl from '../../BaseUrl';
import Swal from 'sweetalert2';
import {
    CitySelect,
    CountrySelect,
    StateSelect,
    LanguageSelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";

const AddCity = () => {


    const navigate = useNavigate()




    const [countryData, setCountryData] = useState([
        {
            name: 'Pakistan'
        }
    ])



    const [name, setName] = useState(null)
    const [country, setCountry] = useState(null)
    const [countryId, setCountryId] = useState(null)
    const [state, setState] = useState(null)

    const submit = () => {
        if (!name) {
            toast('🚨 Must enter city name!')
        }
        else if (!country) {
            toast('🚨 Must select country!')
        }
        else {

            try {
                const param = {
                    name: name,
                    country: country,
                }


                axios.post(`${BaseUrl.baseUrl}/city/create`, param).then(res => {
                    if (res.data.status === 'ok') {
                        toast('🎉 Created Successfully!')
                        navigate('/city')
                    }
                    else if (res.data.status === 'fail') {
                        toast('🚩' + res.data.message)
                    }

                }).catch(err => {
                    toast('🚨' + err.message)
                })


            } catch (err) {
                toast('🚨 Something went wrong!')
            }

        }

    }



    return (
        <div>
            <div>
                <Header name={'City'} />
            </div>


            <div className='bg-light rounded-2 p-3  mb-4 me-3'>
                <div className='d-flex justify-content-between align-items-start mb-3'>
                    <h3>Add City</h3>
                    <div className='p-1 px-3 bg-warning rounded-2 d-flex justify-content-center align-items-center set-shadow' style={{ cursor: 'pointer' }} onClick={() => { navigate(-1) }}>
                            <h5 className='m-0 p-0'>Back</h5>
                        </div>
                </div>


                <div className='row  w-100'>



                    <div className='col-md-4 mb-4'>


                        <CountrySelect
                            onChange={(e) => {
                                console.log(e, 'country');
                                setCountry(e.name)
                                setCountryId(e.id);
                            }}
                            placeHolder="Select Country"
                        />

                    </div>


                    <div className='col-md-4 mb-4'>


                        <StateSelect
                            countryid={countryId}
                            onChange={(e) => {
                                console.log(e,'state');
                                setState(e.id);
                            }}
                            placeHolder="Select State"
                        />

                    </div>


                    <div className='col-md-4 mb-4'>

                        <CitySelect
                            countryid={countryId}
                            stateid={state}
                            onChange={(e) => {
                                setName(e.name)
                                console.log(e,'city');
                            }}
                            placeHolder="Select City"
                        />

                    </div>






                </div>



                <div className='p-1 px-3 bg-warning rounded-2 d-flex justify-content-center align-items-center set-shadow' style={{ cursor: 'pointer', width: '140px' }} onClick={() => { submit() }}>
                    <h5 className='m-0 p-0'>Submit</h5>
                </div>

            </div>



        </div>
    )
}

export default AddCity