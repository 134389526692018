import React, { useEffect, useState } from "react";
import Header from "../../Components/Header";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import BaseUrl from "../../BaseUrl";
import toast from "react-simple-toasts";
import Swal from "sweetalert2";
import moment from "moment";
import { Carousel } from "react-responsive-carousel";
import { Slide } from 'react-slideshow-image';
import { Modal } from 'react-responsive-modal';
import {
  Magnifier,
  GlassMagnifier,
  MOUSE_ACTIVATION,
  TOUCH_ACTIVATION,
} from "react-image-magnifiers";

const ViewReport = () => {
  const navigate = useNavigate();

  const { id } = useParams();

  const [images, setImages] = useState([]);
  const [data, setData] = useState(null);

  const [imgModal, setImgModal] = useState(false)
  const [updModal, setupdModal] = useState(false)
  const [newScore, setNewScore] = useState(null)





  const updateTaskScore = (mydata) => {
    try {


      const param = new FormData()

      param.append('givenScore', mydata?.questions?.reduce(
        (accumulator, currentValue) =>
          parseInt(accumulator) + parseInt(currentValue?.givenScore ? currentValue?.givenScore : 0),
        0
      ))
      param.append('totalScore', mydata?.questions?.reduce(
        (accumulator, currentValue) =>
          parseInt(accumulator) + parseInt(currentValue?.totalScore ? currentValue?.totalScore : 0),
        0
      ))




      axios
        .put(`${BaseUrl.baseUrl}/task/update/${mydata?._id}`, param)
        .then((res) => {
          if (res.data.status === "ok") {

            axios
              .get(`${BaseUrl.baseUrl}/task/report/${id}`)
              .then((res) => {
                if (res.data.status === "ok") {
                  setData(res.data.data);
                } else if (res.data.status === "fail") {
                  toast("🚩" + res.data.message);
                }
              })
              .catch((err) => {
                toast("🚨" + err.message);
              });
          } else if (res.data.status === "fail") {
            toast("🚩" + res.data.message);
          }
        })
        .catch((err) => {
          toast("🚨" + err.message);
        });




    } catch (err) {
      toast("🚨 Something went wrong!");
    }
  };





  const updateScore = (mydata) => {
    try {

      mydata?.questions?.map(i => {

        const param = new FormData()

        param.append('givenScore', i?.subquestions?.reduce(
          (accumulator, currentValue) =>
            parseInt(accumulator) + parseInt(currentValue?.givenScore ? currentValue?.givenScore : 0),
          0
        ))
        param.append('totalScore', i?.subquestions?.reduce(
          (accumulator, currentValue) =>
            parseInt(accumulator) + parseInt(currentValue?.totalScore ? currentValue?.totalScore : 0),
          0
        ))




        axios
          .put(`${BaseUrl.baseUrl}/question/update/${i?._id}`, param)
          .then((res) => {
            if (res.data.status === "ok") {
              updateTaskScore(mydata)
            } else if (res.data.status === "fail") {
              toast("🚩" + res.data.message);
            }
          })
          .catch((err) => {
            toast("🚨" + err.message);
          });


      })

    } catch (err) {
      toast("🚨 Something went wrong!");
    }
  };





  useEffect(() => {
    try {
      axios
        .get(`${BaseUrl.baseUrl}/task/report/${id}`)
        .then((res) => {
          console.log(res.data);
          if (res.data.status === "ok") {
            setData(res.data.data);
            updateScore(res.data?.data)
          } else if (res.data.status === "fail") {
            toast("🚩" + res.data.message);
          }
        })
        .catch((err) => {
          toast("🚨" + err.message);
        });
    } catch (err) {
      toast("🚨 Something went wrong!");
    }
  }, [newScore]);

  const [qType, setQType] = useState(null)
  const [sQuest, setSQuest] = useState(null)
  const [newAns, setNewAns] = useState(null)
  const [subNewAns, setNewSubAns] = useState([])



  const [clickData, setClickData] = useState(null)
  const [fullData, setFullData] = useState([])





  // const [qType, setQType] = useState(null)
  const [saQuest, setSAQuest] = useState(null)










  const del = (nid, newArray) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          var param = new FormData();

          // Always append newArray as a JSON string
          param.append('newimage', JSON.stringify(newArray || []));

          axios
            .put(`${BaseUrl.baseUrl}/question/updateDel/${nid}`, param)
            .then((res) => {
              if (res.data.status === "ok") {
                toast("🎉 Deleted Successfully!");

                axios
                  .get(`${BaseUrl.baseUrl}/task/report/${id}`)
                  .then((res) => {
                    if (res.data.status === "ok") {
                      setData(res.data.data);
                    } else if (res.data.status === "fail") {
                      toast("🚩" + res.data.message);
                    }
                  })
                  .catch((err) => {
                    toast("🚨" + err.message);
                  });
              } else if (res.data.status === "fail") {
                toast("🚩" + res.data.message);
              }
            })
            .catch((err) => {
              toast("🚨" + err.message);
            });
        } catch (err) {
          toast("🚨 Something went wrong!");
        }

      }
    });
  };





  const addimg = (nid, newArray) => {
    try {

      if (newArray?.length === 0) {
        toast("🚨 Must select image!");
      }
      else {

        console.log(newArray);
        const param = new FormData()
        newArray?.map(i => {
          param.append('image', i)
        })



        axios
          .put(`${BaseUrl.baseUrl}/question/update/${nid}`, param)
          .then((res) => {
            if (res.data.status === "ok") {
              toast("🎉 Updated Successfully!");

              axios
                .get(`${BaseUrl.baseUrl}/task/report/${id}`)
                .then((res) => {
                  if (res.data.status === "ok") {
                    setData(res.data.data);
                    // window.location.reload()
                  } else if (res.data.status === "fail") {
                    toast("🚩" + res.data.message);
                  }
                })
                .catch((err) => {
                  toast("🚨" + err.message);
                });
            } else if (res.data.status === "fail") {
              toast("🚩" + res.data.message);
            }
          })
          .catch((err) => {
            toast("🚨" + err.message);
          });
      }
    } catch (err) {
      toast("🚨 Something went wrong!");
    }
  };









  const updsubQuest = (nid) => {
    try {


      const param = new FormData()
      if (newAns) {
        param.append('answer', newAns)
      }
      if (newScore) {
        param.append('givenScore', newScore)
      }
      if (subNewAns) {
        subNewAns?.map(j => {
          param.append('subAnswer', j)
        })
      }




      axios
        .put(`${BaseUrl.baseUrl}/subQuestion/update/${nid}`, param)
        .then((res) => {
          if (res.data.status === "ok") {
            toast("🎉 Updated Successfully!");
            setNewSubAns([])
            axios
              .get(`${BaseUrl.baseUrl}/task/report/${id}`)
              .then((res) => {
                if (res.data.status === "ok") {
                  setData(res.data.data);
                  // window.location.reload()
                } else if (res.data.status === "fail") {
                  toast("🚩" + res.data.message);
                }
              })
              .catch((err) => {
                toast("🚨" + err.message);
              });
          } else if (res.data.status === "fail") {
            toast("🚩" + res.data.message);
          }
        })
        .catch((err) => {
          toast("🚨" + err.message);
        });

    } catch (err) {
      toast("🚨 Something went wrong!");
    }
  };






  const updtask = () => {
    Swal.fire({
      title: "Are you sure?",
      text: `Do you want to ${data?.block === true ? 'Approve' : 'Pending'} this!`,
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes, ${data?.block === true ? 'Approve' : 'Pending'} it!`,
    }).then((result) => {
      if (result.isConfirmed) {
        try {


          const param = new FormData()
          param.append('block', data?.block === true ? false : true)




          axios
            .put(`${BaseUrl.baseUrl}/task/update/${id}`, param)
            .then((res) => {
              if (res.data.status === "ok") {
                toast("🎉 Updated Successfully!");

                axios
                  .get(`${BaseUrl.baseUrl}/task/report/${id}`)
                  .then((res) => {
                    if (res.data.status === "ok") {
                      setData(res.data.data);
                      // window.location.reload()
                    } else if (res.data.status === "fail") {
                      toast("🚩" + res.data.message);
                    }
                  })
                  .catch((err) => {
                    toast("🚨" + err.message);
                  });
              } else if (res.data.status === "fail") {
                toast("🚩" + res.data.message);
              }
            })
            .catch((err) => {
              toast("🚨" + err.message);
            });

        } catch (err) {
          toast("🚨 Something went wrong!");
        }

      }
    });
  };






  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setImages((prevImages) => [...prevImages, ...files]);
  };

  const handleRemoveImage = (index) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };




  return (
    <div>
      <div>
        <Header name={"Reports"} />
      </div>

      <div className="bg-light rounded-2 p-3  mb-4 me-3">
        <div className="d-flex justify-content-between align-items-start mb-3">
          <h3>{data?.projectId[0]?.name}</h3>
        </div>


        <div className="d-flex justify-content-between align-items-center">
          <div></div>
          <div>
            <button className={`btn btn-${data?.block === true ? 'success' : 'danger'}`} onClick={updtask}> {data?.block === true ? 'Approve' : 'Pending'} </button>
          </div>
        </div>

        <div class="row w-100">

          <div className="col-md-6">
            <div className="row w-100 mb-3">
              <div className="col-6">
                <h6>
                  <b>Store Name:</b>
                </h6>
              </div>
              <div className="col-6">
                <h6>
                  {data?.storeId[0]?.name}
                </h6>
              </div>
            </div>
            <div className="row w-100 mb-3">
              <div className="col-6">
                <h6>
                  <b>Store Address:</b>
                </h6>
              </div>
              <div className="col-6">
                <h6>
                  {data?.storeId[0]?.address}
                </h6>
              </div>
            </div>
            <div className="row w-100 mb-3">
              <div className="col-6">
                <h6>
                  <b>City:</b>
                </h6>
              </div>
              <div className="col-6">
                <h6>
                  {data?.storeId[0]?.city}
                </h6>
              </div>
            </div>

            <hr />

            <div className="row w-100 mb-3">
              <div className="col-6">
                <h6>
                  <b>Assign To:</b>
                </h6>
              </div>
              <div className="col-6">
                <h6>
                  {data?.assignTo[0]?.firstName + " " + data?.assignTo[0]?.lastName}
                </h6>
              </div>
            </div>

            <div className="row w-100 mb-3">
              <div className="col-6">
                <h6>
                  <b>Last Date:</b>
                </h6>
              </div>
              <div className="col-6">
                <h6>
                  {moment(data?.lastDate).format("YYYY/MM/DD")}
                </h6>
              </div>
            </div>

            <div className="row w-100 mb-3">
              <div className="col-6">
                <h6>
                  <b>Start Date:</b>
                </h6>
              </div>
              <div className="col-6">
                <h6>
                  {moment(data?.startAt).format("YYYY/MM/DD")}
                </h6>
              </div>
            </div>

            <div className="row w-100 mb-3">
              <div className="col-6">
                <h6>
                  <b>Updated Date:</b>
                </h6>
              </div>
              <div className="col-6">
                <h6>
                  {moment(data?.updateAt).format("YYYY/MM/DD")}
                </h6>
              </div>
            </div>

            {data?.note ? <div className="row w-100 mb-3">
              <div className="col-6">
                <h6>
                  <b>Notes:</b>
                </h6>
              </div>
              <div className="col-6">
                <h6>
                  {data?.note}
                </h6>
              </div>
            </div> : null}

            <div className="row w-100 mb-3">
              <div className="col-6">
                <h6>
                  <b>State:</b>
                </h6>
              </div>
              <div className="col-6">
                <h6>
                  {data?.state}
                </h6>
              </div>
            </div>

            <div className="row w-100 mb-3">
              <div className="col-6">
                <h6>
                  <b>Scoring:</b>
                </h6>
              </div>
              <div className="col-6">
                <h6>
                  {`${data?.questions?.reduce(
                    (accumulator, currentValue) =>
                      parseInt(accumulator) + parseInt(currentValue?.givenScore),
                    0
                  )}/${data?.questions?.reduce(
                    (accumulator, currentValue) =>
                      parseInt(accumulator) + parseInt(currentValue?.totalScore),
                    0
                  )}`}
                </h6>
              </div>
            </div>


          </div>
          <div className="col-md-6">
            <div className="row">
              {data?.image?.length > 0 ? data?.image?.map(i => {
                return (<div className="col-md-4">
                  <div className="rounded-2 bg-white p-2 w-100" style={{ height: '200px', objectFit: 'cover' }}>
                    <img src={i} className="w-100 h-100 rounded-2 " />
                  </div>
                </div>)
              }) : null}
            </div>
          </div>

        </div>





      </div>




      {data?.questions?.length > 0 ?

        <div className="bg-light rounded-2 p-3  mb-4 me-3">
          <div className="d-flex justify-content-between align-items-start mb-3">
            <h3>Questions</h3>
          </div>


          <div className="accordion accordion-flush" id="accordionFlushExample">
            {data?.questions?.map((i, index) => {
              return (<>

                <div className="accordion-item  bg-warning">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#flush-collapse${index}`}
                      aria-expanded="false"
                      aria-controls={`flush-collapse${index}`}
                    >
                      {index + 1}- {i?.name}  ({`${i?.subquestions?.reduce(
                        (accumulator, currentValue) =>
                          parseInt(accumulator) + parseInt(currentValue?.givenScore),
                        0
                      )}/${i?.subquestions?.reduce(
                        (accumulator, currentValue) =>
                          parseInt(accumulator) + parseInt(currentValue?.totalScore),
                        0
                      )}`})

                    </button>
                  </h2>
                  {i?.subquestions?.map((j, subindex) => {
                    return (<>
                      <div
                        id={`flush-collapse${index}`}
                        className="accordion-collapse collapse px-5"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <div className="d-flex justify-content-between align-items-center">

                            <div>
                              <h6>
                                {subindex + 1}- {j?.productName}
                              </h6>
                            </div>

                            {i?.type === 'Radio Button' ?
                              <div className="d-flex align-items-center gap-3">

                                <div className="border p-2 rounded-2" style={{ cursor: 'pointer' }}>
                                  <h6>
                                    {`${j?.givenScore} / ${j?.totalScore}`}
                                  </h6>
                                </div>

                                {j?.answer === 'No Stock' ?
                                  <div className="bg-danger text-white p-2 rounded-2" style={{ cursor: 'pointer' }}>
                                    <h6>
                                      No Stock
                                    </h6>
                                  </div> :
                                  <>
                                    {null}
                                    {/* <div className="p-2 rounded-2 border" style={{cursor:'pointer'}}>
                                <h6>
                                  No Stock
                                </h6>
                              </div> */}
                                  </>
                                }
                                {j?.answer === 'Enough' ?
                                  <div className="bg-success text-white p-2 rounded-2" style={{ cursor: 'pointer' }}>
                                    <h6>
                                      Enough
                                    </h6>
                                  </div> : <>

                                    {null}
                                    {/* <div  className="border p-2 rounded-2"  style={{cursor:'pointer'}}>
                                <h6>
                                  Enough
                                </h6>
                              </div> */}

                                  </>
                                }
                                <button
                                  title="Edit"
                                  className="btn btn-outline-info btn-sm"
                                  onClick={() => {
                                    setSQuest(j)
                                    setQType(i?.type)
                                    setupdModal(true)
                                  }}
                                >
                                  <i className="bi bi-pencil fs-6"></i>
                                </button>
                              </div> :


                              i?.type === 'Checkbox' ?
                                <div className="d-flex align-items-center gap-3">
                                  <div className="border p-2 rounded-2" style={{ cursor: 'pointer' }}>
                                    <h6>
                                      {`${j?.givenScore} / ${j?.totalScore}`}
                                    </h6>
                                  </div>


                                  {j?.subAnswer?.map(ite => {
                                    return (
<>
                                      {ite?.length>0?<div className={`border text-white p-2 rounded-2 bg-success`} style={{ cursor: 'pointer' }}>
                                        <h6>
                                          {ite}
                                        </h6>
                                      </div>:null}

                                      </>
                                    )
                                  })}


                                  <button
                                    title="Edit"
                                    className="btn btn-outline-info btn-sm"
                                    onClick={() => {
                                      setSQuest(j)
                                      setQType(i?.type)
                                      setupdModal(true)
                                      setNewSubAns(j?.subAnswer)
                                    }}
                                  >
                                    <i className="bi bi-pencil fs-6"></i>
                                  </button>
                                </div>
                                :
                                i?.type === 'Number' ?
                                  <div className="d-flex align-items-center gap-3">
                                    <div className="border p-2 rounded-2" style={{ cursor: 'pointer' }}>
                                      <h6>
                                        {`${j?.givenScore} / ${j?.totalScore}`}
                                      </h6>
                                    </div>

                                    <div className="border p-2 rounded-2" style={{ cursor: 'pointer' }}>
                                      <h6>
                                        {j?.answer}
                                      </h6>
                                    </div>

                                    <button
                                      title="Edit"
                                      className="btn btn-outline-info btn-sm"
                                      onClick={() => {
                                        setSQuest(j)
                                        setQType(i?.type)
                                        setupdModal(true)
                                      }}
                                    >
                                      <i className="bi bi-pencil fs-6"></i>
                                    </button>
                                  </div>
                                  :
                                  i?.type === 'Text' ?
                                    <div className="d-flex align-items-center gap-3">
                                      <div className="border p-2 rounded-2" style={{ cursor: 'pointer' }}>
                                        <h6>
                                          {`${j?.givenScore} / ${j?.totalScore}`}
                                        </h6>
                                      </div>
                                      <div className="border p-2 rounded-2" style={{ cursor: 'pointer' }}>
                                        <h6>
                                          {j?.answer}
                                        </h6>
                                      </div>

                                      <button
                                        title="Edit"
                                        className="btn btn-outline-info btn-sm"
                                        onClick={() => {
                                          setSQuest(j)
                                          setQType(i?.type)
                                          setupdModal(true)
                                        }}
                                      >
                                        <i className="bi bi-pencil fs-6"></i>
                                      </button>
                                    </div>
                                    : null}

                          </div>

                        </div>
                      </div>



                    </>)
                  })}

                  <div className="row p-3">
                    {i?.image?.length > 0 ? i?.image?.map(item => {
                      return (
                        <div className="col-md-2 mb-2" >
                          <div className="position-relative" >
                            <div className="rounded-2 bg-white p-2 w-100" style={{ height: '140px', objectFit: 'cover', cursor: "pointer" }} onClick={() => {
                              setClickData(item)
                              setFullData(i?.image)
                              setImgModal(true)
                            }}>
                              <img src={item} className="w-100 h-100 rounded-2 " />
                            </div>
                            <div className="btn btn-danger d-flex justify-content-center align-items-center position-absolute top-0 end-0" style={{ width: '35px', height: '35px', borderRadius: '200px' }} onClick={(e) => {
                              e.stopPropagation();
                              const myArr = i?.image?.filter(obj => obj !== item)
                              del(i?._id, myArr)
                            }}>
                              <i className="bi bi-trash-fill"></i>
                            </div>
                          </div>

                        </div>
                      )
                    }) : null}

                    <div className="col-md-2" onClick={() => {
                      setSAQuest(i?._id)
                      // setFullData(i?.image)
                    }}>
                      <div className="rounded-2 bg-white p-2 w-100" style={{ height: '140px', objectFit: 'cover', cursor: "pointer" }} data-bs-toggle="modal" data-bs-target="#addimg"  >
                        <div className="d-flex justify-content-center align-items-center h-100">
                          <i className="bi bi-plus fs-1"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>)
            })}
          </div>






        </div>

        : null}










      <Modal open={imgModal} onClose={() => setImgModal(false)} center>
        <div class="">
          <div class="modal-body">
            <Carousel showArrows={true} useKeyboardArrows={true} >

              {fullData?.length > 0 ? fullData?.map(i => {
                return (<>
                  <div>
                    <GlassMagnifier
                      imageSrc={i}
                      imageAlt="abc"
                      // largeImageSrc={i} // Optional
                      style={{ objectFit: 'contain', height: 'auto' }}
                    />

                    {/* <Magnifier
  imageSrc={i}
  imageAlt="Example"
  largeImageSrc={i} // Optional
  mouseActivation={MOUSE_ACTIVATION.CLICK} // Optional
  touchActivation={TOUCH_ACTIVATION.TAP} // Optional
/> */}
                  </div>
                </>)
              }) : null}

            </Carousel>
          </div>

        </div>
      </Modal>




      <Modal open={updModal} onClose={() => {
        setupdModal(false)
        setNewAns(null)
      }} center>
        <div class="">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalLabel">Edit</h1>
            {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => {
                setupdModal(false)
                setNewAns(null)
              }}></button> */}
          </div>
          <div class="modal-body">
            <div className="d-flex justify-content-between align-items-center gap-5" >

              <div>
                <h6>
                  {sQuest?.productName}
                </h6>
              </div>

              {qType === 'Radio Button' ?
                <div className="d-flex align-items-center gap-3">
                  {newAns === 'No Stock' ?
                    <div className="bg-danger text-white p-2 rounded-2" style={{ cursor: 'pointer' }} onClick={() => {
                      setNewAns('No Stock');
                    }}>
                      <h6>
                        No Stock
                      </h6>
                    </div> :
                    (newAns ?
                      <div className="p-2 rounded-2 border" style={{ cursor: 'pointer' }} onClick={() => {
                        setNewAns('No Stock');
                      }}>
                        <h6>
                          No Stock
                        </h6>
                      </div> :
                      (sQuest?.answer === 'No Stock' ?
                        <div className="bg-danger text-white p-2 rounded-2" style={{ cursor: 'pointer' }} onClick={() => {
                          setNewAns('No Stock');
                        }}>
                          <h6>
                            No Stock
                          </h6>
                        </div> :
                        <div className="p-2 rounded-2 border" style={{ cursor: 'pointer' }} onClick={() => {
                          setNewAns('No Stock');
                        }}>
                          <h6>
                            No Stock
                          </h6>
                        </div>
                      )
                    )
                  }

                  {newAns === 'Enough' ?
                    <div className="bg-success text-white p-2 rounded-2" style={{ cursor: 'pointer' }} onClick={() => {
                      setNewAns('Enough');
                    }}>
                      <h6>
                        Enough
                      </h6>
                    </div> :
                    (newAns ?
                      <div className="border p-2 rounded-2" style={{ cursor: 'pointer' }} onClick={() => {
                        setNewAns('Enough');
                      }}>
                        <h6>
                          Enough
                        </h6>
                      </div> :
                      (sQuest?.answer === 'Enough' ?
                        <div className="bg-success text-white p-2 rounded-2" style={{ cursor: 'pointer' }} onClick={() => {
                          setNewAns('Enough');
                        }}>
                          <h6>
                            Enough
                          </h6>
                        </div> :
                        <div className="border p-2 rounded-2" style={{ cursor: 'pointer' }} onClick={() => {
                          setNewAns('Enough');
                        }}>
                          <h6>
                            Enough
                          </h6>
                        </div>
                      )
                    )
                  }



                </div> :


                qType === 'Checkbox' ?
                  <div className="d-flex align-items-center gap-3">

                    {sQuest?.subOption?.map(i => {
                      return (

                        <div className={`border text-${subNewAns?.includes(i) ?  'white' : 'dark'} p-2 rounded-2 ${subNewAns?.includes(i) ? 'bg-success' :  ''}`} style={{ cursor: 'pointer' }} onClick={() => {
                          if (subNewAns?.includes(i)) {
                            setNewSubAns(subNewAns.filter(j => j !== i))
                          }
                          else {
                            setNewSubAns([...subNewAns, i]);
                          }
                        }}>
                          <h6>
                            {i}
                          </h6>
                        </div>
                      )
                    })}
                    {/* 

                      {newAns === 'No' ?
                        <div className="bg-danger text-white p-2 rounded-2" style={{ cursor: 'pointer' }} onClick={() => {
                          setNewAns('No');
                        }}>
                          <h6>
                            No
                          </h6>
                        </div> :
                        (newAns ?
                          <div className="p-2 rounded-2 border" style={{ cursor: 'pointer' }} onClick={() => {
                            setNewAns('No');
                          }}>
                            <h6>
                              No
                            </h6>
                          </div> :
                          (sQuest?.answer === 'No' ?
                            <div className="bg-danger text-white p-2 rounded-2" style={{ cursor: 'pointer' }} onClick={() => {
                              setNewAns('No');
                            }}>
                              <h6>
                                No
                              </h6>
                            </div> :
                            <div className="p-2 rounded-2 border" style={{ cursor: 'pointer' }} onClick={() => {
                              setNewAns('No');
                            }}>
                              <h6>
                                No
                              </h6>
                            </div>
                          )
                        )
                      }

                      {newAns === 'Yes' ?
                        <div className="bg-success text-white p-2 rounded-2" style={{ cursor: 'pointer' }} onClick={() => {
                          setNewAns('Yes');
                        }}>
                          <h6>
                            Yes
                          </h6>
                        </div> :
                        (newAns ?
                          <div className="border p-2 rounded-2" style={{ cursor: 'pointer' }} onClick={() => {
                            setNewAns('Yes');
                          }}>
                            <h6>
                              Yes
                            </h6>
                          </div> :
                          (sQuest?.answer === 'Yes' ?
                            <div className="bg-success text-white p-2 rounded-2" style={{ cursor: 'pointer' }} onClick={() => {
                              setNewAns('Yes');
                            }}>
                              <h6>
                                Yes
                              </h6>
                            </div> :
                            <div className="border p-2 rounded-2" style={{ cursor: 'pointer' }} onClick={() => {
                              setNewAns('Yes');
                            }}>
                              <h6>
                                Yes
                              </h6>
                            </div>
                          )
                        )
                      }
 */}

                  </div>
                  :
                  qType === 'Number' ?
                    <div className="d-flex align-items-center gap-3">

                      <div className="p-2 rounded-2" style={{ cursor: 'pointer' }}>
                        <input className="form-control" type="number" defaultValue={sQuest?.answer} onChange={(e) => { setNewAns(e.target.value) }} />

                      </div>


                    </div>
                    :
                    qType === 'Text' ?
                      <div className="d-flex align-items-center gap-3">

                        <div className="p-2 rounded-2" style={{ cursor: 'pointer' }}>
                          <input className="form-control" type="text" defaultValue={sQuest?.answer} onChange={(e) => { setNewAns(e.target.value) }} />
                        </div>


                      </div>
                      : null}

            </div>

            <div className="d-flex justify-content-between align-items-center">

              <div>
                <h6>
                  Scoring
                </h6>
              </div>
              <div>
                <div className="p-2 rounded-2" style={{ cursor: 'pointer' }}>
                  <input className="form-control" type="number" max={sQuest?.totalScore} defaultValue={sQuest?.givenScore} onChange={(e) => { setNewScore(e.target.value) }} />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" onClick={() => {
              setNewAns(null)
              setNewSubAns([])
              setNewScore(null)
              setupdModal(false)
            }}>Close</button>
            <button type="button" class="btn btn-primary" onClick={() => {
              if (newScore > sQuest?.totalScore) {
                console.log(newScore);
                toast('Must enter score less then total score')
              } else {
                updsubQuest(sQuest?._id)
                setupdModal(false)
                setNewAns(null)
                setNewSubAns([])
              }
            }}>Update</button>
          </div>
        </div>
      </Modal>






      <div class="modal fade" id="addimg" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">Edit</h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => {
                setImages([])
                setSAQuest(null)
              }}></button>
            </div>
            <div class="modal-body">
              <input className="form-control" type="file" onChange={handleFileChange} multiple />
              <div className="d-flex align-items-center gap-3 flex-wrap">
                {images.length > 0 && images.map((image, index) => (
                  <div style={{ width: '90px', height: '90px', borderRadius: '20px' }} className=" bg-white my-3 border set-shadow position-relative">
                    <img
                      src={URL.createObjectURL(image)}
                      alt={`preview ${index}`}
                      width="100%"
                      height={'100%'}
                      style={{ borderRadius: '20px' }}
                    />


                    <div className="btn btn-danger d-flex justify-content-center align-items-center position-absolute top-0 end-0" style={{ width: '35px', height: '35px', borderRadius: '200px' }} onClick={() => handleRemoveImage(index)}>
                      <i className="bi bi-trash-fill"></i>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" onClick={() => {
                setImages([])
                setSAQuest(null)
              }}>Close</button>
              <button type="button" class="btn btn-primary" data-bs-dismiss="modal" aria-label="Close" onClick={() => { addimg(saQuest, images) }}>Update</button>
            </div>
          </div>
        </div>
      </div>




    </div>
  );
};

export default ViewReport;
