import React, { useContext, useEffect, useState } from 'react'
import Header from '../../Components/Header'
import { useNavigate } from 'react-router-dom'
import toast from 'react-simple-toasts';
import axios from 'axios';
import BaseUrl from '../../BaseUrl';

const AddProject = () => {


    const navigate = useNavigate()


    const [countryData, setCountryData] = useState([])


    useEffect(() => {
        try {

            axios.get(`${BaseUrl.baseUrl}/city/get`).then(res => {
                if (res.data.status === 'ok') {
                    setCountryData(res.data.data)
                }
                else if (res.data.status === 'fail') {
                    toast('🚩' + res.data.message)
                }

            }).catch(err => {
                toast('🚨' + err.message)
            })


        } catch (err) {
            toast('🚨 Something went wrong!')
        }

    }, [])





    const [name, setName] = useState(null)
    const [image, setImage] = useState(null)
    const [about, setAbout] = useState(null)
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [showpassword, setShowPassword] = useState(false);
    const [cofirmPassword, setConfirmPassword] = useState(null);


    const submit = () => {
        if (!name) {
            toast('🚨 Must enter project name!')
        }
        else if (!image) {
            toast('🚨 Must select project image!')
        }
        else if (!email) {
            toast('🚨 Must enter email!')
        }
        else if (!password) {
            toast('🚨 Must enter password!')
        }
        else if (password !== cofirmPassword) {
            toast('🚨 Password and confirm password must be same!')
        }
        else if (!about) {
            toast('🚨 Must enter about project!')
        }
        else {

            try {

                const param = new FormData()
                param.append('image', image)
                param.append('name', name)
                param.append('about', about)
                param.append('email', email)
                param.append('password', password)


                axios.post(`${BaseUrl.baseUrl}/project/create`, param).then(res => {
                    if (res.data.status === 'ok') {
                        toast('🎉 Created Successfully!')
                        navigate('/project')
                    }
                    else if (res.data.status === 'fail') {
                        toast('🚩' + res.data.message)
                    }

                }).catch(err => {
                    toast('🚨' + err.message)
                })


            } catch (err) {
                toast('🚨 Something went wrong!')
            }

        }

    }



    return (
        <div>
            <div>
                <Header name={'Project'} />
            </div>


            <div className='bg-light rounded-2 p-3  mb-4 me-3'>
                <div className='d-flex justify-content-between align-items-start mb-3'>
                    <h3>Add Project</h3>
                    {/* <div className='p-1 px-3 bg-warning rounded-2 d-flex justify-content-center align-items-center set-shadow' style={{cursor:'pointer'}} onClick={()=>{navigate('/addcompany')}}>
                        <h5 className='m-0 p-0'>Add</h5>
                    </div> */}
                </div>


                <div className='row  w-100'>


                    <div className='col-md-6 mb-4'>
                        <div className='border border-dark rounded-2 bg-white d-flex align-items-center px-3'>
                            <i className='bi bi-pencil'></i>
                            <input type='text' className='border-0 rounded-2 p-2 w-100 px-3' placeholder='Enter Project Name' onChange={(e) => { setName(e.target.value) }} />
                        </div>
                    </div>

                    <div className='col-md-6 mb-4'>
                        <div className='border border-dark rounded-2 bg-white d-flex align-items-center px-3'>
                            <i className='bi bi-pencil'></i>
                            <input type='file' className='border-0 rounded-2 p-2 w-100 px-3' placeholder='Enter Logo' onChange={(e) => { setImage(e.target.files[0]) }} />
                        </div>
                    </div>


                    <div className="col-md-6 mb-4">
            <div className="border border-dark rounded-2 bg-white d-flex align-items-center px-3">
              <i className="bi bi-pencil"></i>
              <input
                type="email"
                className="border-0 rounded-2 p-2 w-100 px-3"
                placeholder="Enter Your Email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>
          </div>



          <div className="col-md-6 mb-4">
            <div className="border border-dark rounded-2 bg-white d-flex align-items-center px-3">
              <i className="bi bi-pencil"></i>
              <input
                type={showpassword?"text":"password"}
                className="border-0 rounded-2 p-2 w-100 px-3"
                placeholder="Enter Your Password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              {showpassword?<i className="bi bi-eye-fill fs-5" onClick={()=>{setShowPassword(!showpassword)}}></i>:<i className="bi bi-eye-slash-fill fs-5" onClick={()=>{setShowPassword(!showpassword)}}></i>}
            </div>
          </div>

          <div className="col-md-6 mb-4">
            <div className="border border-dark rounded-2 bg-white d-flex align-items-center px-3">
              <i className="bi bi-pencil"></i>
              <input
                type={showpassword?"text":"password"}
                className="border-0 rounded-2 p-2 w-100 px-3"
                placeholder="Enter Your Confirm Password"
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
              />
              {showpassword?<i className="bi bi-eye-fill fs-5" onClick={()=>{setShowPassword(!showpassword)}}></i>:<i className="bi bi-eye-slash-fill fs-5" onClick={()=>{setShowPassword(!showpassword)}}></i>}
            </div>
          </div>





                    <div className='col-md-12 mb-4'>
                        <div className='border border-dark rounded-2 bg-white d-flex align-items-center px-3 h-auto'>
                            <i className='bi bi-pencil'></i>
                            <textarea className='border-0 rounded-2 p-2 w-100 px-3' placeholder='Enter about Project' onChange={(e) => { setAbout(e.target.value) }} />
                        </div>
                    </div>


                </div>



                <div className='p-1 px-3 bg-warning rounded-2 d-flex justify-content-center align-items-center set-shadow' style={{ cursor: 'pointer', width: '140px' }} onClick={() => { submit() }}>
                    <h5 className='m-0 p-0'>Submit</h5>
                </div>

            </div>



        </div>
    )
}

export default AddProject